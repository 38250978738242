import api from "../../api";
/* eslint-disable */
export default {
  namespaced: true,
  state: {
    PackageDetailsTxt: [],
  },
  getters: {
    getPackageDetailsTxt(state) {
      return state.PackageDetailsTxt;
    },
  },
  mutations: {
    SetPackageDetailsTxt(state, data) {
      state.PackageDetailsTxt = [];
      state.PackageDetailsTxt = data;
    },
  },
  actions: {
    fetchAllPackageDetailsTxt({ commit },id) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `packageDetailsTxt/getAllPackageDetailsTxts`,
            {id:id},
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
           
            commit("SetPackageDetailsTxt", res.data);
             resolve(res);
          })
          .catch((err) => resolve(err));
      })
    },


    addPackageDetailsTxt({ }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `packageDetailsTxt/addPackageDetailsTxt`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },

    deletePackageDetailsTxt({ }, id) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `packageDetailsTxt/deletePackageDetailsTxt`,
            { id: id },
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {

            resolve(res);

          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },
  },
};
