import api from "../../api";
/* eslint-disable */
export default {
  namespaced: true,
  state: {
    ContractorCaptains: [],
  },
  getters: {
    getContractorCaptains(state) {
      return state.ContractorCaptains;
    },
  },
  mutations: {
    SetContractorCaptains(state, data) {
      state.ContractorCaptains = [];
      state.ContractorCaptains = data;
    },
  },
  actions: {

    getContractor_captainByCaptainId({ commit }, id) {
      return new Promise((resolve, rej) => {
      api()
        .post(
          `ContractorCaptain/getContractorCaptainsByCaptainId`,
          {
            id: id,
          },
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
          resolve(res);
          commit("SetContractorCaptains", res.data);
        })
        .catch((err) => console.log(err));});
    },
    getCaptainByContractorId({ commit }, id) {
      return new Promise((resolve, rej) => {
      api()
        .post(
          `ContractorCaptain/getCaptainByContractorId`,
          {
            id: id,
          },
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
          resolve(res);
          commit("SetContractorCaptains", res.data);
        })
        .catch((err) => console.log(err));});
    },
    addContractorCaptain({ dispatch }, { contractor_id,captainId}) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `ContractorCaptain/addContractorCaptain`,
            {contractor_id:contractor_id,
              captainId:captainId             
            },
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },


    deleteContractorCaptain({ dispatch }, serial) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `ContractorCaptain/deleteContractorCaptain`,
            { serial: serial },
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {

            resolve(res);

          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },


   
  },
};
