import api from '../../api';
/* eslint-disable */
export default {
    namespaced: true,
    state: {
        pagesData: [],
        iconsData: [],
        parentData: []
    },
    getters: {
        getpagesData(state) {
            return state.pagesData;
        },
        getpagesDataForPickList(state) {
            var pagesDataForPickList=[];
            for(var i=0; i<state.pagesData.length;i++){
                pagesDataForPickList.push(state.pagesData[0]);
            }
            return state.pagesData;
        },
        geticonsData(state) {
            return state.iconsData;
        },
        getparentData(state) {
            return state.parentData;
        },
    },
    mutations: {
        SetpagesData(state, data) {
            ;
            state.pagesData = [];
            state.pagesData = data;
        },
        SeticonsData(state, data) {
            ;
            state.iconsData = [];
            state.iconsData = data;
        },
        SetparentData(state, data) {
            ;
            state.parentData = [];
            state.parentData = data;
        },
    },
    actions: {
        fetchpagesData({ commit }) {

            api().post(`pages/get_pages`,{},{
                headers: {
                    'Authorization': localStorage.getItem("JwtToken")
                }
            })
                .then(res => {
                    commit("SetpagesData", res.data.data);
                })
                .catch(err => {
                })

        },
        fetchIconsData({ commit }) {

            api().post(`pages/get_icons`,{},{
                headers: {
                    'Authorization': localStorage.getItem("JwtToken")
                }
            })
                .then(res => {
                    commit("SeticonsData", res.data.data);
                })
                .catch(err => {
                })

        },
        fetchparentData({ commit }) {

            api().post(`pages/get_parent`,{},{
                headers: {
                    'Authorization': localStorage.getItem("JwtToken")
                }
            })
                .then(res => {
                    commit("SetparentData", res.data.data);
                })
                .catch(err => {
                })

        },
        addpagesData({ dispatch }, content) {
            return new Promise((resolve, rej) => {
                
                api().post(`pages/add_page`,
                    { name: content.name, parentId: content.parentId, route: content.route, pageIconId: content.pageIconId },{
                        headers: {
                            'Authorization': localStorage.getItem("JwtToken")
                        }
                    }
                )
                    .then(res => {
                        if (res.status == 200) {
                            resolve(res)
                            dispatch("fetchpagesData")

                        } else {

                            resolve({ status: res.data.status, message: res.data.message })

                        }
                    }
                    );
            });
        },
        editpagesData({ dispatch }, content) {
            return new Promise((resolve, rej) => {
                api().post(`pages/edit_page`,
                    { id: content.id, name: content.name, parentId: content.parentId, route: content.route, pageIconId: content.pageIconId },{
                        headers: {
                            'Authorization': localStorage.getItem("JwtToken")
                        }
                    }
                )
                    .then(res => {

                        if (res.status == 200) {
                            resolve(res)
                            dispatch("fetchpagesData")

                        } else {

                            resolve({ status: res.data.status , message: res.data.message })

                        }
                    }
                    );
            });
        },

        delpagesData({ dispatch }, content) {

            return new Promise((resolve) => {
                api().post(`/pages/delete_page`,
                    { id: content },{
                        headers: {
                            'Authorization': localStorage.getItem("JwtToken")
                        }
                    }
                )
                    .then(res => {

                        if (res.status == 200) {
                            resolve(res)
                            dispatch("fetchpagesData")

                        } else {

                            resolve({ status:  res.data.status, message: res.data.message })

                        }
                    }
                    );
            });
        },


    }
}