import api from "../../api";
/* eslint-disable */
export default {
  namespaced: true,
  state: {
    RideCategory: [],
  },
  getters: {
    getRideCategories(state) {
      return state.RideCategory;
    },
  },
  mutations: {
    SetRideCategories(state, data) {
      state.RideCategory = [];
      state.RideCategory = data;
    },
  },
  actions: {
    fetchAllRideCategories({ commit }) {
      return new Promise((resolve, rej) => {
      api()
        .post(
          `RideCategory/get_ride_categories`,
          {},
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
          resolve(res);
          commit("SetRideCategories", res.data);
        })
        .catch((err) => console.log(err));});
    },
    fetchRideCategoriesById({ commit }, id) {
      api()
        .post(
          `RideCategory/get_ride_categories_by_id`,
          {
            id: id,
          },
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
          resolve(res);
          commit("SetRideCategories", res.data);
        })
        .catch((err) => console.log(err));
    },

    addRideCategory({ dispatch }, { name }) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `RideCategory/add_ride_category`,
            {
              name: name,
             
            },
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },
    editRideCategory(
      { dispatch },
      { id, name  }
    ) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `RideCategory/edit_ride_category`,
            {
              id: id,
              name: name,
             
            },
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },

    deleteRideCategory({ dispatch }, id) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `RideCategory/delete_ride_category`,
            { id: id },
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },
  },
};
