import api from "../../api";
export default {
  namespaced: true,
  state: {
    dummyRidesByDate: [],
  },
  getters: {
    getDummyRidesByDate(state) {
      return state.dummyRidesByDate;
    },
  },
  mutations: {
    SetDummyRides(state, data) {
      state.dummyRidesByDate = [];
      state.dummyRidesByDate = data;
    },
  },
  actions: {
    getDummyRidesByDate({ commit }, data) {
      
      return new Promise((resolve, rej) => {
        api()
          .post(`DummyRides/getDmmyRidesbyDate`, {
            "rideDate": data.rideDate,

          }, {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          })
          .then((res) => {
            resolve(res);
            commit("SetDummyRides", res.data.data)
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },
    addDummyRideById({ commit }, data) {
     
      return new Promise((resolve, rej) => {
        api()
          .post(`DummyRides/addFakeRide`, {
            "dummyRideId": data.dummyRideId,

          }, {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          })
          .then((res) => {
           
            resolve(res);
            
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    }
  }
};