import api from "../../api";
export default {
  namespaced: true,
  state: {
    chatData: [],
    chatDetailes: [],
    currentChatReciverId: 0,
  },
  getters: {
    getChatData(state) {
      return state.chatData;
    },
    getChatDetailes(state) {
      return state.chatDetailes;
    },
  },
  mutations: {
    SetChatData(state, data) {
      state.chatData = [];
      state.chatData = data;
    },
    SetChatDetailes(state, data) {
      state.chatDetailes = [];
      state.chatDetailes = data;
    },
  },
  actions: {
    fetchAllChatData({ commit }) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `chat/get_all_chats`,
            {},
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            commit("SetChatData", res.data.data);
            resolve(res.data);
          })
          .catch((err) => console.log(err));
      });
    },
    fetchChatDetailes({ commit, state }, reciverId) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `chat/get_chat_detailes`,
            { reciverId: reciverId },
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            state.currentChatReciverId = reciverId;
            commit("SetChatDetailes", res.data.data);
            resolve(res.data);
          })
          .catch((err) => console.log(err));
      });
    },
    send_message({ commit, state }, { reciverId, message, chatRoomId }) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `chat/send_message`,
            { reciverId: reciverId, message: message, chatRoomId: chatRoomId },
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            console.log(res);
            if (res.status == 200) {
              state.chatDetailes[state.chatDetailes.length - 1].push(res.data.data);
            }
            resolve(res.data);
          })
          .catch((err) => console.log(err));
      });
    },
    markAsSeen({ commit }, reciverId) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `chat/markAsSeen`,
            { reciverId: reciverId },
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => console.log(err));
      });
    },
  },
};
