import api from "../../api";
/* eslint-disable */
export default {
  namespaced: true,
  state: {
    ChangeReasions: [],
  },
  getters: {
    getChangeReasions(state) {
      return state.ChangeReasions;
    },
  },
  mutations: {
    SetChangeReasions(state, data) {
      state.ChangeReasions = [];
      state.ChangeReasions = data;
    },
  },
  actions: {
    fetchAllChangeReasions({ commit }) {
      return new Promise((resolve, rej) => {
      api()
        .post(
          `captainChangeReasions/getCptainChangeReasions`,
          {},
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
          resolve(res);
          commit("SetChangeReasions", res.data);
        })
        .catch((err) => console.log(err));})
    },


    addCptainChangeReasion({ dispatch }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `captainChangeReasions/addCptainChangeReasion`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },
    editeCptainChangeReasion(
      { dispatch },
      data
    ) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `captainChangeReasions/editeCptainChangeReasion`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {

            resolve(res);

          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },

    deleteCptainChangeReasion({ dispatch }, id) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `captainChangeReasions/deleteCptainChangeReasion`,
            { id: id },
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {

            resolve(res);

          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },
  },
};
