import { Number } from "core-js";
import api from "../api";
// import axios from "axios";
import { convertStringToDate } from "@/components/dummyRidesbooking/functions/convertStringToDate";

// axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
export default {
  namespaced: true,
  state: {
    ContractorInvoice: [],
    ContractorInvoice: [],
    CaptainTransactions: [],

  },
  getters: {
    getContractorInvoice(state) {
      return state.ContractorInvoice;
    }, getContractorTransactions(state) {
      return state.ContractorTransactions;
    }, getCaptainTransactions(state) {
      return state.CaptainTransactions;
    },
  },
  mutations: {
    SetContractorInvoice(state, data) {
      state.ContractorInvoice = [];
      state.ContractorInvoice = data.map(ride => ({
        ...ride,
        fromDate: new Date(ride.fromDate),
        toDate: new Date(ride.toDate),
        invoiceCreationDate: new Date(ride.invoiceCreationDate),
        transactionWithOutInvoice: ride.transactionWithOutInvoice == null ? null : new Date(ride.transactionWithOutInvoice),
        cashFromCaptainPaid: ride.cashFromCaptainPaid == null ? 0 : ride.cashFromCaptainPaid,
        cashFromCaptainAmount: ride.cashFromCaptainAmount == null ? 0 : Number(ride.cashFromCaptainAmount),
        remainingAmount: ride.remainingAmount == null ? 0 : Number(ride.remainingAmount),
        amountPaid: ride.amountPaid == null ? 0 : Number(ride.amountPaid),

      }))
    },
    SetContractorTransactions(state, data) {
      state.ContractorTransactions = [];
      state.ContractorTransactions = data.map(ride => ({
        ...ride,
        transactionDate: new Date(ride.transactionDate),


      }))
    },
    SetCaptainTransactions(state, data) {
      state.CaptainTransactions = [];
      state.CaptainTransactions = data.map(ride => ({
        ...ride,
        rideDate: new Date(ride.rideDate),


      })).sort((a, b) => b.id - a.id);
    },
  },
  actions: {
    getInvoice({ commit }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `invoices/getInvoice`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            commit("SetContractorInvoice", res.data);
            resolve()
          })
          .catch((err) => {

            console.log(err)
          });
      })
    },
    getInvoiceforContarctor({ commit }) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `invoices/getInvoiceforContarctor`,
            {},
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            commit("SetContractorInvoice", res.data);
            resolve()
          })
          .catch((err) => {

            console.log(err)
          });
      })
    },
    getContractorTransactionsByinvoiceId({ commit }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `invoices/getContractorTransactionsByinvoiceId`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            commit("SetContractorTransactions", res.data);
            resolve()
          })
          .catch((err) => {

            console.log(err)
          });
      })
    },

    getCaptainTransactionsByinvoiceId({ commit }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `invoices/getCaptainTransactionsByinvoiceId`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            commit("SetCaptainTransactions", res.data);
            resolve()
          })
          .catch((err) => {

            console.log(err)
          });
      })
    },


    getTransactionWithOutInvoice({ commit }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `invoices/TransactionWithOutInvoice`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res.data)
          })
          .catch((err) => {

            console.log(err)
          });
      })
    },

    addInvoice(
      { dispatch }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `invoices/addInvoice`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {

            resolve(res);
          })
          .catch((err) => {
            resolve(err.response.data);
          });
      });
    },

    payingMoneyToContractor(
      { dispatch }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `invoices/payingMoneyToContractor`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {

            resolve(res);
          })
          .catch((err) => {
            resolve(err.response.data);
          });
      });
    },
    ContractorCashCollection(
      { dispatch }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `invoices/ContractorCashCollection`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {

            resolve(res);
          })
          .catch((err) => {
            resolve(err.response.data);
          });
      });
    },
    captainCashCollectionInvice(
      { dispatch }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `invoices/captainCashCollectionInvice`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {

            resolve(res);
          })
          .catch((err) => {
            resolve(err.response.data);
          });
      });
    },
    payingMoneyToCaptain(
      { dispatch }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `invoices/payingMoneyToCaptain`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {

            resolve(res);
          })
          .catch((err) => {
            resolve(err.response.data);
          });
      });
    },

    deleteInvoice(
      { dispatch }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `invoices/deleteInvoice`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {

            resolve(res);
          })
          .catch((err) => {
            resolve(err.response.data);
          });
      });
    },


  },
};
