import api from "../../api";
/* eslint-disable */
import { convertStringToDate } from "@/components/dummyRidesbooking/functions/convertStringToDate";

export default {
  namespaced: true,
  state: {
    captainTransactions: [],
       allCaptainTransactions: [],

  },
  getters: {
    getCaptainTransactions(state) {
      return state.captainTransactions;
    }, 
    getallCaptainTransactions(state) {
      return state.allCaptainTransactions;
    },
  },
  mutations: {
    SetCaptainTransactions(state, data) {
      
      state.captainTransactions = [];
      state.captainTransactions = data.map(ride => ({
        ...ride,
        fulldateTime: convertStringToDate(ride.fulldateTime)
      }));
    }, 
    SetAllCaptainTransactions(state, data) {
      
      state.allCaptainTransactions = [];
      state.allCaptainTransactions = data;
    },
  },
  actions: {
    getCaptainTransactions({ commit }) {
      return new Promise((resolve, rej) => {
      api()
        .post(
          `captainTransactions/getCaptainTransactions`,
          {},
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
          resolve(res);
          commit("SetAllCaptainTransactions", res.data);
        })
        .catch((err) => console.log(err));})
    },

    getCaptainTransactionsByCaptainId({ commit },data) {
      return new Promise((resolve, rej) => {
      api()
        .post(
          `captainTransactions/getCaptainTransactionsByCaptainId`,
          data,
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
          resolve(res);
          commit("SetCaptainTransactions", res.data);
        })
        .catch((err) => console.log(err));})
    },
    addDuesPayment({ },data) {
      return new Promise((resolve, rej) => {
      api()
        .post(
          `captainTransactions/addDuesPayment`,
          data,
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => console.log(err));})
    },
    addCashCollection({},data) {
      return new Promise((resolve, rej) => {
      api()
        .post(
          `captainTransactions/addCashCollection`,
          data,
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => console.log(err));})
    },

    
  },
};
