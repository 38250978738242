import api from "../../api";
/* eslint-disable */
export default {
  namespaced: true,
  state: {
    contractors: [],
    PersonDoc: [],
    Activecontractors: [],

  },
  getters: {
    getcontractors(state) {
      return state.contractors;
    }, getPersonDoc(state) {
      return state.PersonDoc;
    }, getActivecontractors(state) {
      return state.Activecontractors;
    },
  },
  mutations: {
    Setcontractors(state, data) {
      state.contractors = [];
      state.contractors = data;
    }, SetPersonDoc(state, data) {
      state.PersonDoc = [];
      state.PersonDoc = data;
    }, SetActivecontractors(state, data) {
      state.Activecontractors = [];
      state.Activecontractors = data;
    },
  },
  actions: {
    fetchAllcontractors({ commit }) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `contractors/get_all_contractors`,
            {},
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);

            commit("Setcontractors", res.data);
          })
          .catch((err) => console.log(err));
      })
    },
    getActivecontractors({ commit }) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `contractors/getActivecontractors`,
            {},
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);

            commit("SetActivecontractors", res.data);
          })
          .catch((err) => console.log(err));
      })
    },

    fetchpersonDocBypersonID({ commit }, id) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `contractors/get_personDoc_by_personID`,
            {
              id: id,
            },
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
            commit("SetPersonDoc", res.data);
          })
          .catch((err) => console.log(err));
      });
    },



    addcontractor({ }, formData) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `contractors/add_contractor`,
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',

                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },
    editcontractor(
      { },
      formData
    ) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `contractors/edite_contractor`,
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',

                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {

            resolve(res);

          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },

    deletecontractor({ }, id) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `contractors/delete_contractor`,
            { id: id },
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {

            resolve(res);

          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },

    UploadCaptionFile({ }, Formdata) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `contractors/UploadCaptionFile`,
            Formdata,
            {
              headers: {
                'Content-Type': 'multipart/form-data',

                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },







    removeCaptionFile({ }, { contractorsId, docNameId }) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `contractors/removeCaptionFile`,
            { docNameId: docNameId, contractorsId: contractorsId },
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },





  },
};
