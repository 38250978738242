import api from '../../api';
export default {
    namespaced: true,
    state: {
        Routers: [],
    },
    getters: {
        getRouters(state) {
            return state.Routers;
        }   
    },
    mutations: {
        SetRouters(state, data) {            
            state.Routers = [];
            state.Routers = data;
        },
    },
    actions: {       
        fetchRouters({ commit }, content) {
            return new Promise((resolve, rej) => {
                api().post(`/Routers/getPagesToRouter`,
                    { },{
                        headers: {
                            'Authorization': localStorage.getItem("JwtToken")
                        }
                    }
                )
                    .then(res => {

                        if (res.status == 200) {
                            commit("SetRouters", res.data.data);
                            resolve(res)
                        } else {
                            resolve(res)
                        }
                    }
                    );
            });
        },
    }
}