import api from "../../api";
/* eslint-disable */
export default {
  namespaced: true,
  state: {
    Package: [],
  },
  getters: {
    getPackage(state) {
      return state.Package;
    },
  },
  mutations: {
    SetPackage(state, data) {
      state.Package = [];
      state.Package = data;
    },
  },
  actions: {
    fetchAllPackage({ commit }) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `packages/getAllPackages`,
            {},
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
           
            commit("SetPackage", res.data);
             resolve(res);
          })
          .catch((err) => resolve(err));
      })
    },


    addPackage({ }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `packages/addPackage`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },
    editPackage(
      { dispatch },
      data
    ) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `packages/editePackage`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {

            resolve(res);

          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },

    deletePackage({ }, id) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `packages/deletePackage`,
            { id: id },
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {

            resolve(res);

          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },
  },
};
