import api from "../../api";
/* eslint-disable */
export default {
  namespaced: true,
  state: {
    lastCoustomerTransaction: [],
    CoustomerTransaction: [],

  },
  getters: {
    getlastCoustomerTransaction(state) {
      return state.lastCoustomerTransaction;
    },getCoustomerTransaction(state) {
      return state.CoustomerTransaction;
    },
  },
  mutations: {
    SetlastCoustomerTransaction(state, data) {
      state.lastCoustomerTransaction = [];
      state.lastCoustomerTransaction = data;
    },SetCoustomerTransaction(state, data) {
      
      state.CoustomerTransaction = [];
      state.CoustomerTransaction = data;
    },
  },
  actions: {
    lastCoustomerTransaction({ commit },data) {
      return new Promise((resolve, rej) => {
      api()
        .post(
          `coustomerTransactions/lastCoustomerTransaction`,
          data,
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
          resolve(res);
          commit("SetlastCoustomerTransaction", res.data);
        })
        .catch((err) => console.log(err));})
    },
  
    getCoustomerTransactionByCoustomerId({ commit },data) {
      return new Promise((resolve, rej) => {
      api()
        .post(
          `coustomerTransactions/getCoustomerTransactionByCoustomerId`,
          data,
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
          resolve(res);
          commit("SetCoustomerTransaction", res.data);
        })
        .catch((err) => console.log(err));})
    },
    addCustomerMoney({  },data) {
      return new Promise((resolve, rej) => {
      api()
        .post(
          `coustomerTransactions/addCustomerMoney`,
          data,
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => console.log(err));})
    },
    addCustomerdeduction({  },data) {
      return new Promise((resolve, rej) => {
      api()
        .post(
          `coustomerTransactions/addCustomerdeduction`,
          data,
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => console.log(err));})
    },

  },
};
