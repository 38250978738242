import api from '../../api';
/* eslint-disable */
export default {
    namespaced: true,
    state: {
        groupsData: [],
    },
    getters: {
        getgroupsData(state) {
            return state.groupsData;
        },
    },
    mutations: {
        SetgroupsData(state, data) {
            ;
            state.groupsData = [];
            state.groupsData = data;
        },
    },
    actions: {
        fetchgroupsData({ commit }) {


            api().post(`groups/get_groups`,{},{
                headers: {
                    'Authorization': localStorage.getItem("JwtToken")
                }
            })
                .then(res => {
                    commit("SetgroupsData", res.data.data);
                })
                .catch(err => {
                })

        },

        addgroupsData({ dispatch }, name) {
            return new Promise((resolve, rej) => {

                api().post(`groups/add_group`,
                    { name: name },{
                        headers: {
                            'Authorization': localStorage.getItem("JwtToken")
                        }
                    }
                )
                    .then(res => {
                        if (res.status == 200) {
                            dispatch("fetchgroupsData")
                            resolve(res)

                        } else {

                            resolve(res)

                        }
                    }
                    );
            });
        },

        editgroupsData({ dispatch }, content) {
            return new Promise((resolve, rej) => {
                api().post(`groups/edit_group`,
                    { id: content.id, name: content.name },{
                        headers: {
                            'Authorization': localStorage.getItem("JwtToken")
                        }
                    }
                )
                    .then(res => {

                        if (res.status == 200) {
                            dispatch("fetchgroupsData")
                            resolve(res)
                        } else {

                            resolve(res)
                        }
                    }
                    );
            });
        },

        delgroupsData({ dispatch }, content) {

            return new Promise((resolve, rej) => {
                api().post(`/groups/delete_group`,
                    { id: content },{
                        headers: {
                            'Authorization': localStorage.getItem("JwtToken")
                        }
                    }
                )
                    .then(res => {

                        if (res.status == 200) {
                            dispatch("fetchgroupsData")
                            resolve(res)
                        } else {

                            resolve(res)
                        }
                    }
                    );
            });
        },


    }
}