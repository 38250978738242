import api from "../../api";
/* eslint-disable */
export default {
  namespaced: true,
  state: {
    DocName: [],
  },
  getters: {
    getDocName(state) {
      
      return state.DocName;
    },
  },
  mutations: {
    SetDocName(state, data) {
      state.DocName = [];
      state.DocName = data;
    },
  },
  actions: {
    fetchAllDocName({ commit }) {
      return new Promise((resolve, rej) => {
      api()
        .post(
          `DocName/get_all_doc_names`,
          {},
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
          resolve(res);
          commit("SetDocName", res.data);
        })
        .catch((err) => console.log(err));});
    },
    fetchDocNameByBus_captain_f({ commit }, bus_captain_f) {
      return new Promise((resolve, rej) => {

      api()
        .post(
          `DocName/get_doc_names_by_bus_captain_f`,
          {
            bus_captain_f: bus_captain_f,
          },
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
           resolve(res);
          commit("SetDocName", res.data);
        })
        .catch((err) =>    resolve(err));})
    },

    addDocName({ dispatch }, { docName,bus_captain_f,required_f }) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `DocName/add_doc_name`,
            {
              docName: docName,bus_captain_f:bus_captain_f,required_f:required_f
             
            },
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },
    editDocName(
      {  },
      { id, docName, bus_captain_f, required_f}
    ) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `DocName/edite_doc_name`,
            {
              id: id,
              docName: docName,bus_captain_f:bus_captain_f,required_f:required_f
             
            },
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },

    deleteDocName({ dispatch }, id) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `DocName/delete_doc_name`,
            { id: id },
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },
  },
};
