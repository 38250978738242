import api from "../../api";
export default {
  namespaced: true,
  state: {
    countries: [],
  },
  getters: {
    getCountries(state) {
      return state.countries;
    },
  },
  mutations: {
    SetCountries(state, data) {
      state.countries = [];
      state.countries = data;
    },
  },
  actions: {
    fetchAllCountries({ commit }) {
      api()
        .post(
          `country/get_all_countries`,
          {},
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
          commit("SetCountries", res.data);
        })
        .catch((err) => console.log(err));
    },

    addCountry({}, { name, coordinates }) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `country/add_country`,
            {
              name: name,
              coordinates: coordinates,
            },
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },
    editCountry({}, { id, name, coordinates }) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `country/edite_country`,
            {
              id: id,
              name: name,
              coordinates: coordinates,
            },
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },

    deleteCountry({}, id) {
      return new Promise((resolve, rej) => {

        api()
          .post(
            `country/delete_country`,
            { id: id },
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },
  },
};
