import api from "../api";
/* eslint-disable */
export default {
  namespaced: true,
  state: {
    RoutStops: [],

  },
  getters: {
    getRoutStops(state) {
      return state.RoutStops;
    },
  },
  mutations: {
    SetRoutStops(state, data) {
      state.RoutStops = [];
      state.RoutStops = data
    },

  },
  actions: {


    getBookingByDate({ commit },data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `RoutStops/getRoutStopsById`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            commit("SetRoutStops", res.data);
            resolve()
          })
          .catch((err) => {

            console.log(err)
          });
      })
    },
    
  },
};
