import api from '../../api';
/* eslint-disable */
export default {
    namespaced: true,
    state: {
        usersData: [],
        user: [],
    },
    getters: {
        getusersData(state) {
            return state.usersData;
        }, 
        getUser(state) {
            return state.user;
        },
    },
    mutations: {
        SetusersData(state, data) {
            state.usersData = [];
            state.usersData = data;
        },  
        Setuser(state, data) {
            state.user = [];
            state.user = data[0];
        },
    },
    actions: {
        fetchusersData({ commit }) {
            api().post(`users/get_users`,{},{
                headers: {
                    'Authorization': localStorage.getItem("JwtToken")
                }
            })
                .then(res => {
                    commit("SetusersData", res.data.data);
                })
                .catch(err => {
                })

        }, 
       

        getUserById({ commit },data) {
            api().post(`users/getUserById`,data,{
                headers: {
                    'Authorization': localStorage.getItem("JwtToken")
                }
            })
                .then(res => {
                    console.log(res.data)
                    commit("Setuser", res.data);
                })
                .catch(err => {
                })

        },
        addusersData({ dispatch }, content) {
            return new Promise((resolve, rej) => {
                api().post(`users/add_user`,
                    { userName: content.userName, password: content.password, groupId: content.groupId, personID: content.personID, },{
                        headers: {
                            'Authorization': localStorage.getItem("JwtToken")
                        }
                    }
                )
                    .then(res => {

                        if (res.status == "200") {

                            dispatch("fetchusersData")
                            resolve({ status: true, message: res.data.message })

                        } else {

                            resolve({ status: false, message: res.data.message })
                        }
                    }
                    );
            });
        },
        editusersData({ dispatch }, content) {
            return new Promise((resolve, rej) => {
                api().post(`users/edit_user`,
                    { id: content.id, userName: content.userName, password: content.password, groupId: content.groupId, personID: content.personID, },{
                        headers: {
                            'Authorization': localStorage.getItem("JwtToken")
                        }
                    }
                )
                    .then(res => {

                        if (res.status == "200") {
                            dispatch("fetchusersData")
                            resolve({ status: true, message: res.data.message })
                        } else {

                            resolve({ status: false, message: res.data.message })
                        }
                    }
                    );
            });
        },

        delusersData({ dispatch }, content) {

            return new Promise((resolve, rej) => {
                api().post(`/users/delete_user`,
                    { id: content },{
                        headers: {
                            'Authorization': localStorage.getItem("JwtToken")
                        }
                    }
                )
                    .then(res => {

                        if (res.data.StatusCode == "200") {
                            dispatch("fetchusersData")
                            resolve({ status: true, message: res.data.message })

                        } else {

                            resolve({ status: false, message: res.data.message })
                        }
                    }
                    );
            });
        },


    }
}