import api from "../api";
/* eslint-disable */
export default {
  namespaced: true,
  state: {
    promoCodes: [],

  },
  getters: {
    getpromoCodes(state) {
      return state.promoCodes;
    },
  },
  mutations: {
    SetpromoCodes(state, data) {
      state.promoCodes = [];
      state.promoCodes = data;
    },

  },
  actions: {

    getpromoCodes({ commit },data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `promoCodes/get_user_promoCodes`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            commit("SetpromoCodes", res.data);
            resolve()
          })
          .catch((err) => {

            console.log(err)
          });
      })
    },
    
  },
};
