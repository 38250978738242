import api from "../../api";
/* eslint-disable */
export default {
  namespaced: true,
  state: {
    ContractorTransaction: [],
    ContractorTransactionByContractor:[]

  },
  getters: {
    getContractorTransaction(state) {
      return state.ContractorTransaction;
    },
    getContractorTransactionByContractor(state) {
      return state.ContractorTransactionByContractor;
    },
  },
  mutations: {
    SetContractorTransaction(state, data) {
      state.ContractorTransaction = [];
      state.ContractorTransaction = data;
    },SetContractorTransactionByContractor(state, data) {
      state.ContractorTransactionByContractor = [];
      state.ContractorTransactionByContractor = data;
    },
  },
  actions: {
 
  
    getContractorTransactions({ commit },data) {
      return new Promise((resolve, rej) => {
      api()
        .post(
          `contractorTransaction/getContractorTransactions`,
          data,
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
          resolve(res);
          commit("SetContractorTransaction", res.data);
        })
        .catch((err) => console.log(err));})
    },
    addContractorDuesPayment({  },data) {
      return new Promise((resolve, rej) => {
      api()
        .post(
          `contractorTransaction/addContractorDuesPayment`,
          data,
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => console.log(err));})
    },
    addContractorCashCollection({  },data) {
      return new Promise((resolve, rej) => {
      api()
        .post(
          `contractorTransaction/addContractorCashCollection`,
          data,
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => console.log(err));})
    },
    getContractorTransactionsByContractorId({ commit },data) {
      return new Promise((resolve, rej) => {
      api()
        .post(
          `contractorTransaction/getContractorTransactionsByContractorId`,
          data,
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
          resolve(res);
          commit("SetContractorTransactionByContractor", res.data);

        })
        .catch((err) => console.log(err));})
    },
  },
};
