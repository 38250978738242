import api from "../../api";
import { initializeApp } from "firebase/app";
import { getMessaging, onMessage, getToken } from "firebase/messaging";
export default {
  namespaced: true,
  state: {
    app: null,
    messaging: null,
    firebaseConfig: {
      apiKey: "AIzaSyAW8fSRM_RWI1vTu8MOyKmviSivTDz0Mq8",
      authDomain: "rego-87c36.firebaseapp.com",
      projectId: "rego-87c36",
      storageBucket: "rego-87c36.appspot.com",
      messagingSenderId: "936729863066",
      appId: "1:936729863066:web:5068913e7617ae0d205023",
      measurementId: "G-P34JYFXFWJ",
    },
  },
  getters: {
    getMessagings(state) {
      return state.messaging;
    },
  },
  mutations: {
    SetSchedules(state, data) {
      state.schedules = [];
      state.schedules = data;
    },
  },
  actions: {
    initializeFirebase({ state }) {
      return new Promise((resolve, rej) => {
        state.app = initializeApp(state.firebaseConfig);
        state.messaging = getMessaging(state.app);
        getToken(state.messaging, {
          vapidKey:
            "BA_s4YcBeQNyhOeBJ9OEDLTGlAJffxZoRN_aDDKs66-StNGEEmYFrMzrcEtdgJEQIE8JKBrSzysil8nSKppSFws",
        })
          .then((currentToken) => {
            if (currentToken) {
              console.log("token:" + currentToken);
              resolve(currentToken);
            } else {
              console.log(
                "No registration token available. Request permission to generate one."
              );
              resolve(null);
            }
          })
          .catch((err) => {
            console.log("An error occurred while retrieving token. ", err);
            resolve(null);
          });
      });
    },
  },
};
