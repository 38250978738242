import api from "../../api";
/* eslint-disable */
export default {
  namespaced: true,
  state: {
    CaptainNote: [],

  },
  getters: {
    getCaptainNote(state) {
      return state.CaptainNote;
    }
  },
  mutations: {
    SetCaptainNote(state, data) {
      state.CaptainNote = [];
      state.CaptainNote = data;
    }
  },
  actions: {
    getCaptainNotes({ commit },data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `captainNotes/getCaptainNotes`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);

            commit("SetCaptainNote", res.data);
          })
          .catch((err) => console.log(err));
      });
    },

    addCaptainNotes({ }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `captainNotes/addCaptainNotes`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },
    editCaptainNotes({ }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `captainNotes/editCaptainNotes`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },





  },
};
