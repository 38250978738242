import api from "../../api";
import { convertStringToDate } from "@/components/dummyRidesbooking/functions/convertStringToDate";

/* eslint-disable */
export default {
  namespaced: true,
  state: {
    Search_for_daily_rides: [],
    CacheLogs: [],


  },
  getters: {
    getSearch_for_daily_rides(state) {
      return state.Search_for_daily_rides;
    },
    getCacheLogs(state) {
      return state.CacheLogs;
    },

  },
  mutations: {
   


    SetSearch_for_daily_rides(state, data) {
      state.Search_for_daily_rides = [];
      state.Search_for_daily_rides = data.map(ride => ({
        ...ride,
        fullRideDate: convertStringToDate(ride.rideDate)
      }));
  
    }, SetCacheLogs(state, data) {
      state.CacheLogs = [];
      state.CacheLogs = data;
    }
  },
  actions: {


    search_for_daily_rides({ commit }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `SearchForRides/new_search_for_daily_rides`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
            debugger
            commit("SetSearch_for_daily_rides", res.data.data);
          })
          .catch((err) => resolve(err));
      })
    },
    search_for_daily_rides_cache({ commit }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `SearchForRides/search_for_daily_rides_cache`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {

            resolve(res);
            debugger
            commit("SetSearch_for_daily_rides", res.data);
          })
          .catch((err) => resolve(err));
      })
    },
    getCacheLogs({ commit }) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `SearchForRides/getCacheLogs`,
            {},
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {

            resolve(res);
            commit("SetCacheLogs", res.data);
          })
          .catch((err) => resolve(err));
      })
    },
    SetCacheGovernate({ commit }) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `SearchForRides/SetCacheGovernate`,
            {},
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {

            resolve(res);
            commit("SetCacheLogs", res.data);
          })
          .catch((err) => resolve(err));
      })
    },
    SetCacheAreas({ commit }) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `SearchForRides/SetCacheAreas`,
            {},
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {

            resolve(res);
            commit("SetCacheLogs", res.data);
          })
          .catch((err) => resolve(err));
      })
    },
    SetCacheStops({ commit }) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `SearchForRides/SetCacheStops`,
            {},
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {

            resolve(res);
            commit("SetCacheLogs", res.data);
          })
          .catch((err) => resolve(err));
      })
    },
    SetCacheRouteStops({ commit }) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `SearchForRides/SetCacheRouteStops`,
            {},
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {

            resolve(res);
            commit("SetCacheLogs", res.data);
          })
          .catch((err) => resolve(err));
      })
    },
    SetCacheDummyRides({ commit }) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `SearchForRides/SetCacheDummyRides`,
            {},
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {

            resolve(res);
            commit("SetCacheLogs", res.data);
          })
          .catch((err) => resolve(err));
      })
    },
  },
};
