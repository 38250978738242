import api from '../../api';
export default {
    namespaced: true,
    state: {
        menuData: [],
    },
    getters: {
        getmenuData(state) {
            return state.menuData;
        }   
    },
    mutations: {
        SetmenuData(state, data) {            
            state.menuData = [];
            state.menuData = data;
        },
    },
    actions: {       
        fetchmenuData({ commit }, content) {
            return new Promise((resolve, rej) => {
                api().post(`/menu/get_menu`,
                    { },{
                        headers: {
                            'Authorization': localStorage.getItem("JwtToken")
                        }
                    }
                )
                    .then(res => {

                        if (res.status == 200) {
                            commit("SetmenuData", res.data.data);
                            resolve(res)
                        } else {
                            resolve(res)
                        }
                    }
                    );
            });
        },
    }
}