import api from "../../api";
/* eslint-disable */
export default {
  namespaced: true,
  state: {
    year: [],
  },
  getters: {
    getYear(state) {
      return state.year;
    },
  },
  mutations: {
    SetYears(state, data) {
      state.year = [];
      state.year = data;
    },
  },
  actions: {
    fetchAllYear({ commit }) {
      return new Promise((resolve, rej) => {
      api()
        .post(
          `Years/get_all_years`,
          {},
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
          resolve(res);
          commit("SetYears", res.data);
        })
        .catch((err) => console.log(err));});
    },
    fetchYearsById({ commit }, id) {
      return new Promise((resolve, rej) => {
      api()
        .post(
          `Years/get_years_by_id`,
          {
            id: id,
          },
          
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
          resolve(res);
          commit("SetYear", res.data);
        })
        .catch((err) => console.log(err));});
    },

    addyear({ dispatch }, { name }) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `Years/add_year`,
            {
              name: name,
             
            },
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },
    editYear(
      { dispatch },
      { id, name  }
    ) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `Years/edit_year`,
            {
              id: id,
              name: name,
             
            },
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {

            resolve(res);

          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },

    deleteyear({ dispatch }, id) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `Years/delete_year`,
            { id: id },
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {

            resolve(res);

          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },
  },
};
