import api from '../../api';
/* eslint-disable */
export default {
    namespaced: true,
    state: {
        groupPagesData: [],
        pagesData: [],
    },
    getters: {
        getgroupPagesData(state) {
            return state.groupPagesData;
        },
        getpagesData(state) {
            return state.pagesData;
        },
    },
    mutations: {
        SetgroupPagesData(state, data) {
            state.groupPagesData = [];
            state.groupPagesData = data;
        },
        SetpagesData(state, data) {
            state.pagesData = [];
            state.pagesData = data;
        },
    },
    actions: {
        fetchPagesData({ commit }) {
            return new Promise((resolve, rej) => {
            api().post(`groupPages/get_Pages`,{},{
                headers: {
                    'Authorization': localStorage.getItem("JwtToken")
                }
            })
                .then(res => {
                    commit("SetpagesData", res.data.data);
                    resolve();
                })
                .catch(err => {
            });
        })
        },

        fetchgroupPagesData({ commit }, groupId) {
            return new Promise((resolve, rej) => {
                api().post(`groupPages/get_group_pages`, {
                    groupId: groupId
                },{
                    headers: {
                        'Authorization': localStorage.getItem("JwtToken")
                    }
                })
                    .then(res => {
                        commit("SetgroupPagesData", res.data.data);
                        resolve();
                    })
                    .catch(err => {
                    })
            });
        }, fetchSetPagesData({ commit }, payload) {
            return new Promise((resolve, rej) => {
                api().post(`groupPages/set_group_pages`, {
                    groupId: payload.groupId,
                    pagesList:payload.pagesList
                },{
                    headers: {
                        'Authorization': localStorage.getItem("JwtToken")
                    }
                })
                    .then(res => {


                        if (res.status == 200) {
                            commit("SetgroupPagesData", res.data.data);
                            resolve(res)
                        } else {

                            resolve(res)
                        }



                    })
                    .catch(err => {
                    })
            });
        },
    }
}