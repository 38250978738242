import axios from "axios";
import https from "https-browserify";
import { useRouter, useRoute } from "vue-router";
const router = useRouter();
import { serverBasePath } from "./store/consts";

export default () => {
  const agent = new https.Agent({  
    rejectUnauthorized: false
  });
  const axiosInstance = axios.create({
    baseURL: serverBasePath + "api",
    withCredentials: false,
    httpsAgent: agent,
    headers: {
      Accept: "application/json",
    },
  });
  axiosInstance.interceptors.response.use(
    (response) => {
      if (response.status == 401) {
        localStorage.removeItem("JwtToken");
        router.push("/");
        location.reload();
        return Promise.resolve(response);
      } else {
        return Promise.resolve(response);
      }
    },
    (error) => {
      // Handle response errors here
      return Promise.reject(error);
    }
  );
  return axiosInstance;
};
