import api from "../../api";
/* eslint-disable */
export default {
  namespaced: true,
  state: {
    bus: [],
    busdoc:[],
    BusWithoutCaptains:[]
  },
  getters: {
    getbus(state) {
      return state.bus;
    },getbusdoc(state) {
      return state.busdoc;
    },getBusWithoutCaptains(state) {
      return state.BusWithoutCaptains;
    },
  },
  mutations: {
    Setbus(state, data) {
      state.bus = [];
      state.bus = data;
    },Setbusdoc(state, data) {
      state.busdoc = [];
      state.busdoc = data;
    },SetBusWithoutCaptains(state, data) {
      state.BusWithoutCaptains = [];
      state.BusWithoutCaptains = data;
    },
  },
  actions: {
    fetchAllbus({ commit }) {
      return new Promise((resolve, rej) => {
      api()
        .post(
          `Buses/get_all_Buses`,
          {},
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
          resolve(res);

          commit("Setbus", res.data);
        })
        .catch((err) => console.log(err));});
    },   
    get_all_BusesRealRide({ commit }) {
      return new Promise((resolve, rej) => {
      api()
        .post(
          `Buses/get_all_BusesRealRide`,
          {},
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
          resolve(res);

          commit("Setbus", res.data);
        })
        .catch((err) => console.log(err));});
    }, 
    
    FetchBusesWithoutCaptains({ commit }) {
      return new Promise((resolve, rej) => {
      api()
        .post(
          `Buses/getBusesWithoutCaptains`,
          {},
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
          commit("SetBusWithoutCaptains", res.data);
        })
        .catch((err) => console.log(err));});
    },
    fetchbusById({ commit }, id) {
      return new Promise((resolve, rej) => {
      api()
        .post(
          `Buses/getBusbyBusid`,
          {
            id: id,
          },
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {

          commit("Setbus", res.data);
          resolve(res);
        })
        .catch((err) =>  resolve(err));});
    },
    getBusesByBusesTypeid({ commit }, id) {
      return new Promise((resolve, rej) => {
      api()
        .post(
          `Buses/getBusesByBusesTypeid`,
          {
            id: id,
          },
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
          resolve(res);
          commit("Setbus", res.data);
        })
        .catch((err) => console.log(err));});
    },
    addbus({ }, {plateNumber,
      selectedmake,
      selectedBusType,
      selectedColor,
      selectedYear,
      selectedModel,
      active_f,
      expireLicenseDate}) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `Buses/add_bus`,
            {
              plateNumber: plateNumber,
              makeId: selectedmake,
              busTypeId: selectedBusType,
              colorId: selectedColor,
              yearId: selectedYear,
              modelId: selectedModel,
              active_f: active_f,
              expireLicenseDate: expireLicenseDate,
            },
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },
    editbus(
      { },
      { id, plateNumber,
        selectedmake,
        selectedBusType,
        selectedColor,
        selectedYear,
        selectedModel,
        active_f,
        expireLicenseDate }
    ) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `Buses/edite_bus`,
            {
              id: id,
              plateNumber: plateNumber,
              makeId: selectedmake,
              busTypeId: selectedBusType,
              colorId: selectedColor,
              yearId: selectedYear,
              modelId: selectedModel,
              active_f: active_f,
              expireLicenseDate: expireLicenseDate,
            },
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },



deletebus({ }, id) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `Buses/delete_bus`,
            { id: id },
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },


    UploadFile({ }, Formdata) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `Buses/UploadFile`,
            Formdata,
            {
              headers: {
                'Content-Type': 'multipart/form-data',

                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },



    fetchbusDocBybusid({ commit }, id) {
      api()
        .post(
          `Buses/get_busDocs_by_busid`,
          {
            id: id,
          },
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
          commit("Setbusdoc", res.data);
        })
        .catch((err) => console.log(err));
    },


    
    removebusDocImage({ }, {busId,docNameId}) {
  return new Promise((resolve, rej) => {
    api()
      .post(
        `Buses/removeFile`,
        { docNameId: docNameId,busId:busId },
        {
          headers: {
            Authorization: localStorage.getItem("JwtToken"),
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        resolve(err.response);
      });
  });
},

GetImage({ }, {busId,docNameId}) {
  return new Promise((resolve, rej) => {
    api()
      .post(
        `Buses/GetImage`,
        { docNameId: docNameId,busId:busId },
        {
          headers: {
            Authorization: localStorage.getItem("JwtToken"),
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        resolve(err.response);
      });
  });
},


    
  },
};
