import api from "../api";
import { convertStringToDate } from "@/components/dummyRidesbooking/functions/convertStringToDate";

/* eslint-disable */
export default {
  namespaced: true,
  state: {
    DummyRides: [],
    RideStops: [],
    DummyRidesByBooking:[],
    PassengerBydummy:[]
  },
  getters: {
    getDummyRides(state) {
      return state.DummyRides;
    },
    getRideStops(state) {
      return state.RideStops;
    }, getDummyRidesByBooking(state) {
      return state.DummyRidesByBooking;
    },getPassengerBydummy(state) {
      return state.PassengerBydummy;
    },
  },
  mutations: {
    SetDummyRides(state, data) {
      state.DummyRides = [];
      state.DummyRides = data
      .map(ride => ({
        ...ride,
        fullRideDate1: new Date(ride.fullRideDate),
        startTimeDate: convertStringToDate(ride.fullRideDate), 
        bookingCount2: Number(ride.bookingCount2)
    
      }));;
    },
    SetRideStops(state, data) {
      state.RideStops = [];
      state.RideStops = data;
    },SetDummyRidesByBooking(state, data) {
      state.DummyRidesByBooking = [];
      state.DummyRidesByBooking = data;
    },SetPassengerBydummy(state, data) {
      state.PassengerBydummy = [];
      state.PassengerBydummy = data;
    },
  },
  actions: {
 
    fetchAllDummyRides({ commit },data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `DummyRides/getDmmyRides`,
           data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);

            commit("SetDummyRides", res.data);
          })
          .catch((err) => console.log(err));
      });
    },
    fetchAllDummyRidesById({}, id) {
      return new Promise((resolve, rej) => {
        api()
          .post(`DummyRides/getDmmyRideById`,{ id:id}, {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          })
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },

    addDmmyRidesParent({ dispatch }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(`DummyRides/addDmmyRidesParent`, data, {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          })
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },

    deleteDmmyRidesParent({ dispatch }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(`DummyRides/deleteDmmyRidesParent`, data, {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          })
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },
    deleteDmmyRidesChildren({}, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(`DummyRides/deleteDmmyRidesChildren`, data, {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          })
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },
    addRealRide({}, data) {
      
      console.log(data);
     return new Promise((resolve, rej) => {
        api()
          .post(`realRides/addRealRide`, data, {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          })
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },
    removeRealDummy({}, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(`realRides/removeRealDummy`, data, {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          })
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },  
    addDummyRideToDumyRideWithReal({}, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(`DummyRides/addDummyRideToDumyRideWithReal`, data, {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          })
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },



    
    getDummyRideStopsByDummyRideId({ commit }, id) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `DummyRideStops/getDummyRideStopsByDummyRideId`,
            { id: id },
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            commit("SetRideStops", res.data);
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },   

    
    getRideStopsByRideId({ commit }, id) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `DummyRideStops/getRideStopsByRideId`,
            { id: id },
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            commit("SetRideStops", res.data);
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    }, 









    
    getDummyRidesByBookingId({ commit },data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `dummyRides/getDummyRidesByBookingId`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            commit("SetDummyRidesByBooking", res.data);
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },

    getPassengerBydummyRideIds({ commit },data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `dummyRides/getPassengerBydummyRideIds`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            commit("SetPassengerBydummy", res.data);
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },

    

    changeDummyRide({ commit }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `dummyRides/changeDummyRide`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
          
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },
    HideDmmyRides({ commit }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `dummyRides/HideDmmyRides`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
          
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },
    hideAllDummyRide({ commit }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `dummyRides/hideAllDummyRide`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
          
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },
    cancelAllDummyRide({ commit }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `dummyRides/cancelAllDummyRide`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
          
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },
    showDmmyRides({ commit }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `dummyRides/showDmmyRides`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
          
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },
    cancelDmmyRides({ commit }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `dummyRides/cancelDmmyRides`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
          
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },
    hideAllDummyRide({ commit }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `dummyRides/hideAllDummyRide`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
          
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },

    cancelAllDummyRide({ commit }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `dummyRides/cancelAllDummyRide`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
          
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },

    
    ActiveDmmyRides({ commit }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `dummyRides/ActiveDmmyRides`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
          
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    }, 
    
    editDummyRideDateTime({ commit }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `dummyRides/editDummyRideDateTime`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
          
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },    
    cancelUserDummyRide({  }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `dummyRides/cancelUserDummyRide`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
          
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },
  },
};
