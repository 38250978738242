import api from "../../api";
/* eslint-disable */
export default {
  namespaced: true,
  state: {
    
    BannedUsers:[]
  },
  getters: {
    getBannedUsers(state) {
      return state.BannedUsers;
    }
  },
  mutations: {
    SetBannedUsers(state, data) {
      state.BannedUsers = [];
      state.BannedUsers = data;
    }
  },
  actions: {
  


   
    addBannedUser(      { },  data   ) {
      
      return new Promise((resolve, rej) => {
        api()
          .post(
            `bannedUsers/add_bannedUser`,data
           ,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },
    editBannedUsers(      { },  data   ) {
      
      return new Promise((resolve, rej) => {
        api()
          .post(
            `bannedUsers/editBannedUsers`,data
           ,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },
    getBannedUsers({ commit },data) {
      return new Promise((resolve, rej) => {
      api()
        .post(
          `bannedUsers/getbannedUsersBycaptainId`,
          data,
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
          resolve(res);

          commit("SetBannedUsers", res.data);
        })
        .catch((err) => console.log(err));});
    }, 



    
  },
};
