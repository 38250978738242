import api from "../../api";
export default {
  namespaced: true,
  state: {
    categories: [],
  },
  getters: {
    getCategories(state) {
      return state.categories;
    },
  },
  mutations: {
    SetCategories(state, data) {
      state.categories = [];
      state.categories = data;
    },
  },
  actions: {
    fetchAllCategories({ commit }) {
      
      api()
        .post(
          `routeCategory/get_all_category`,
          {},
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
          commit("SetCategories", res.data);
        })
        .catch((err) => console.log(err));
    },
  },
};
