import api from "../../api";
export default {
  namespaced: true,
  state: {
    stopsData: [],
    stopsForArea:[],
    RouteStopsPrice:[],
    StopsByGovernateId:[],

  },
  getters: {
    getStopsData(state) {
      return state.stopsData;
    },
    getStopsForArea(state) {
      return state.stopsForArea;
    },    
    getRouteStopsPrice(state) {
      return state.RouteStopsPrice;
    }, getStopsByGovernateId(state) {
      return state.StopsByGovernateId;
    },
  },
  mutations: {
    SetStopsData(state, data) {
      state.stopsData = [];
      state.stopsData = data;
    }, SetRouteStopsPrice(state, data) {
      state.RouteStopsPrice = [];
      state.RouteStopsPrice = data;
    },
    SetStopsForArea(state, data) {
      state.stopsForArea = [];
      state.stopsForArea = data;
    },    SetStopsByGovernateId(state, data) {
      state.StopsByGovernateId = [];
      state.StopsByGovernateId = data;
    },
  },
  actions: {
    fetchAllStopsData({ commit }) {
      return new Promise((resolve, rej) => {
        api()
        .post(
          `stops/get_all_stops`,
          {},
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
          commit("SetStopsData", res.data);
          resolve( res.data);
        })
        .catch((err) => console.log(err));
      });
      
    },

    addstopData({ dispatch }, { lat_long, arName, enName, status }) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `stops/add_stop`,
            {
              lat_long:lat_long,
              arName: arName,
              enName: enName,
              status: status,
            },
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },
    editStopData(
      { dispatch },
      { id, lat_long, arName, enName, status }
    ) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `stops/edite_stop`,
            {
              id: id,
              lat_long:lat_long,
              arName: arName,
              enName: enName,
              status: status,
            },
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },

    deleteStop({ dispatch }, id) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `stops/delte_stop`,
            { id: id },
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },
    fetchStopsToRoute({ commit },id) {
      return new Promise((resolve, rej) => {
        api()
        .post(
          `stops/get_stops_for_route_avalibleSelected`,
          { id: id },
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
          resolve( res.data);
        })
        .catch((err) => console.log(err));
      });
      
    },

    getStopsByGovernateId({ commit },data) {
      return new Promise((resolve, rej) => {
        api()
        .post(
          `stops/getStopsByGovernateId`,
         data,
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
          commit("SetStopsByGovernateId", res.data);

          resolve( res.data);
        })
        .catch((err) => console.log(err));
      });
      
    },



    fetchStopsToRoutePrice({ commit },data) {
      return new Promise((resolve, rej) => {
        api()
        .post(
          `stops/getRouteStopsPrice`,
          data,
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
          
          commit("SetRouteStopsPrice", res.data);

          resolve( res.data);
        })
        .catch((err) => console.log(err));
      });
      
    },
    fetchStopsByArea({ commit },id) {
      return new Promise((resolve, rej) => {
        api()
        .post(
          `stops/get_stops_by_area`,
          { id: id },
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
          commit("SetStopsForArea", res.data);
          resolve( res.data);
        })
        .catch((err) => console.log(err));
      });
      
    },
  },
};
