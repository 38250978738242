import api from "../../api";
/* eslint-disable */
export default {
  namespaced: true,
  state: {
    StartEndReasons: [],
  },
  getters: {
    getStartEndReasons(state) {
      return state.StartEndReasons;
    },
  },
  mutations: {
    SetStartEndReasons(state, data) {
      
      state.StartEndReasons = [];
      state.StartEndReasons = data;
    },
  },
  actions: {
    getStartEndReasons({ commit }) {
      return new Promise((resolve, rej) => {
      api()
        .post(
          `startEndReasons/getStartEndReasons`,
          {},
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
          resolve(res);
          commit("SetStartEndReasons", res.data);
        })
        .catch((err) => console.log(err));})
    },
    getStartReasons({ commit }) {
      return new Promise((resolve, rej) => {
      api()
        .post(
          `startEndReasons/getStartReasons`,
          {},
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
          resolve(res);
          commit("SetStartEndReasons", res.data);
        })
        .catch((err) => console.log(err));})
    },
    getEndReasons({ commit }) {
      return new Promise((resolve, rej) => {
      api()
        .post(
          `startEndReasons/getEndReasons`,
          {},
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
          resolve(res);
          commit("SetStartEndReasons", res.data);
        })
        .catch((err) => console.log(err));})
    },
    addStartEndReason({  }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `startEndReasons/addStartEndReason`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },
    editStartEndReason(
      { dispatch },
      data
    ) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `startEndReasons/editStartEndReason`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {

            resolve(res);

          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },

    deleteStartEndReason({ dispatch }, id) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `startEndReasons/deleteStartEndReason`,
            { id: id },
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {

            resolve(res);

          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },

  },
};
