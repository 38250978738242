import api from "../../api";
/* eslint-disable */
export default {
  namespaced: true,
  state: {
    persons: [],
   
  },
  getters: {
    getPersons(state) {
      return state.persons;
    }
  },
  mutations: {
    SetPersons(state, data) {
      state.persons = [];
      state.persons = data;
    }
  },
  actions: {
    getAllPerson({ commit }) {
      return new Promise((resolve, rej) => {
      api()
        .post(
          `persons/getAllPerson`,
          {},
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
          resolve(res);

          commit("SetPersons", res.data);
        })
        .catch((err) => console.log(err));});
    },   


    
  },
};
