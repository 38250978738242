import api from "../../api";
/* eslint-disable */
export default {
  namespaced: true,
  state: {
    BusType: [],
  },
  getters: {
    getBusType(state) {
      return state.BusType;
    },
  },
  mutations: {
    SetBusType(state, data) {
      state.BusType = [];
      state.BusType = data;
    },
  },
  actions: {
    fetchAllBusType({ commit }) {
    
      return new Promise((resolve, rej) => {  api()
        .post(
          `BusType/get_all_bus_types`,
          {},
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
          resolve(res);
          commit("SetBusType", res.data);
        })
        .catch((err) => console.log(err));});
    },
    fetchBusTypeById({ commit }, id) {
      return new Promise((resolve, rej) => {
      api()
        .post(
          `BusType/get_bus_types_by_id`,
          {
            id: id,
          },
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
          resolve(res);
          commit("SetBusType", res.data);
        })
        .catch((err) => console.log(err));});
    },
    getBusTypesBySeats({ commit }, data) {
      return new Promise((resolve, rej) => {
      api()
        .post(
          `BusType/getBusTypesBySeats`,
         data,
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
          resolve(res);
          commit("SetBusType", res.data);
        })
        .catch((err) => console.log(err));});
    },
    addBusType({ dispatch }, { name,vechileTypeId,seats,maxSeats }) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `BusType/add_bus_type`,
            {
              name: name,
              vechileTypeId:vechileTypeId,
              seats:seats,maxSeats:maxSeats
            },
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },
    editBusType(
      { dispatch },
      { id, name,vechileTypeId,seats,maxSeats  }
    ) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `BusType/edite_bus_type`,
            {
              id: id,
              name: name,
              vechileTypeId:vechileTypeId,
              seats:seats,maxSeats:maxSeats
            },
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },

    deleteBusType({ dispatch }, id) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `BusType/delete_bus_type`,
            { id: id },
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },
  },
};
