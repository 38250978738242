import api from "../../api";
/* eslint-disable */
export default {
  namespaced: true,
  state: {
    vehicleType: [],
  },
  getters: {
    getVehicleType(state) {
      return state.vehicleType;
    },
  },
  mutations: {
    SetVehicleType(state, data) {
      state.vehicleType = [];
      state.vehicleType = data;
    },
  },
  actions: {
    fetchAllVehicleTypes({ commit }) {
      return new Promise((resolve, rej) => {
      api()
        .post(
          `VehicleType/get_all_vehicleType`,
          {},
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
          resolve(res);
          commit("SetVehicleType", res.data);
        })
        .catch((err) => console.log(err));});
    },
    fetchVehicleTypesById({ commit }, id) {
      api()
        .post(
          `VehicleType/get_vehicleType_by_id`,
          {
            id: id,
          },
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
          resolve(res);
          commit("SetVehicleType", res.data);
        })
        .catch((err) => console.log(err));
    },

    addVehicleType({ dispatch }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `VehicleType/add_vehicle_Type`,
            data,
            {
              headers: {
                'Content-Type': 'multipart/form-data',

                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },
    editVehicleType({ dispatch },data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `VehicleType/edit_vehicle_Type`,
         data,
            {
              headers: {
                'Content-Type': 'multipart/form-data',

                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },

    deleteVehicleType({ dispatch }, id) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `VehicleType/delete_vehicle_Type`,
            { id: id },
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },
  },
};
