import api from "../../api";
import { convertStringToDate } from "@/components/dummyRidesbooking/functions/convertStringToDate";

/* eslint-disable */
export default {
  namespaced: true,
  state: {
    realRides: [],
    CaptainRealRides: [],
    CaptainAllRealRides: [],
    RideStops: [],
    Passenger: [],
    RealRidesPlaneNumber: [],
    RealRidesByBooking: [],
    RealRideStatusLog:[],
    RealRidesTodayById:[],
    Captains:[],
    RealRidesPlanDrop:[],

  },
  getters: {
    getrealRides(state) {
      return state.realRides;
    }, getCaptainRealRides(state) {
      return state.CaptainRealRides;
    }, getCaptainAllRealRides(state) {
      return state.CaptainAllRealRides;
    },
    getRideStops(state) {
      return state.RideStops;
    }, getPassenger(state) {
      return state.Passenger;
    }, getRealRidesPlaneNumber(state) {
      return state.RealRidesPlaneNumber;
    }, getRealRidesByBooking(state) {
      return state.RealRidesByBooking;
    },getRealRideStatusLog(state) {
      return state.RealRideStatusLog;
    },getRealRidesTodayById(state) {
      return state.RealRidesTodayById;
    },getCaptains(state) {
      return state.Captains;
    },getRealRidesPlanDrop(state) {
      return state.RealRidesPlanDrop;
    }
  },
  mutations: {
    SetRealRides(state, data) {
      
      state.realRides = [];
      state.realRides = data.map(ride => ({
        ...ride,
        fullRideDate: convertStringToDate(ride.fullRideDate),
        planeNumber:Number(ride.planeNumber),
        booking:Number(ride.booking),
        rideTime:new Date(ride.fullRideDate)

      }));
    }, SetCaptainRealRides(state, data) {

      state.CaptainRealRides = [];
      state.CaptainRealRides = data;
    }, SetCaptainAllRealRides(state, data) {

      state.CaptainAllRealRides = [];
      state.CaptainAllRealRides = data;
    },
    SetRideStops(state, data) {
      state.RideStops = [];
      state.RideStops = data;
    },
    SetPassenger(state, data) {
      state.Passenger = [];
      state.Passenger = data;
    },
    SetRealRidesPlaneNumber(state, data) {
      state.RealRidesPlaneNumber = [];
      state.RealRidesPlaneNumber = data;
    },
    SetRealRidesByBooking(state, data) {
      state.RealRidesByBooking = [];
      state.RealRidesByBooking = data;
    }, 
    SetRealRideStatusLog(state, data) {
      state.RealRideStatusLog = [];
      state.RealRideStatusLog = data;
    },SetRealRidesTodayById(state, data) {
      
      state.RealRidesTodayById = [];
      state.RealRidesTodayById = data.map(ride => ({
        ...ride,
        fullRideDate: convertStringToDate(ride.fullRideDate)
      }))[0];
    },
    SetCaptains(state, data) {
      state.Captains = [];
      state.Captains = data;
    }, 
    SetRealRidesPlanDrop(state, data) {
      state.RealRidesPlanDrop = [];
      state.RealRidesPlanDrop = data;
    },
  },
  actions: {
    fetchAllrealRides({ commit }) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `realRides/getRealRides`,
            {},
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
                notificationToken: localStorage.getItem("notificationToken"),

              },
            }
          )
          .then((res) => {
            resolve(res);

            commit("SetRealRides", res.data);
          })
          .catch((err) => console.log(err));
      });
    },
    fetchAllrealTodayRides({ commit }) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `realRides/getRealRidesToday`,
            {},
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);

            commit("SetRealRides", res.data);
          })
          .catch((err) => console.log(err));
      });
    },
    getRealRidesTodayById({ commit },data) {
      
      return new Promise((resolve, rej) => {
        api()
          .post(
            `realRides/getRealRidesTodayById`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
            
            commit("SetRealRidesTodayById", res.data);
          })
          .catch((err) => console.log(err));
      });
    },
    getRealRidesPlaneNumber({ commit }) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `realRides/getRealRidesPlaneNumber`,
            {},
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);

            commit("SetRealRidesPlaneNumber", res.data);
          })
          .catch((err) => console.log(err));
      });
    },
    editrealRides({ }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `realRideses/edite_realRides`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },

    addStartOrEndToReal({ }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `realRides/addStartOrEndToReal`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },



    changeBusToAllPlaneNumber({ }, data) {
     
      return new Promise((resolve, rej) => {
        api()
          .post(
            `realRides/changeBusToAllPlaneNumber`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            
            resolve(res);
          })
          .catch((err) => {
            
            resolve(err.response);
          });
      });
    },

    removeBusToAllPlaneNumber({ }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `realRides/removeBusToAllPlaneNumber`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },


    cancelRealRide({ }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `realRides/cancelRealRide`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },



    getCaptainRealRidesTodayByCaptianId({ commit }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `realRides/getCaptainRealRidesTodayByCaptianId`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            if (res.status == 200) {

              commit("SetCaptainRealRides", res.data);
            }


            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },



    getCaptainRealRidesByCaptianId({ commit }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `realRides/getCaptainRealRidesByCaptianId`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            if (res.status == 200) {

              commit("SetCaptainAllRealRides", res.data);
            }


            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },

    getCaptainById({ commit },id) {
      return new Promise((resolve, rej) => {
      api()
        .post(
          `captains/getCaptainById`,
          {id:id},
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
                      resolve(res);

          commit("SetCaptains", res.data);
        })
        .catch((err) => console.log(err));});
    },
    getPassengerBydummyRideId({ commit }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `realRides/getPassengerBydummyRideId`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            if (res.status == 200) {

              commit("SetPassenger", res.data);
            }


            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },
    getPassengerByRideId({ commit }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `realRides/getPassengerByRideId`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            if (res.status == 200) {

              commit("SetPassenger", res.data);
            }


            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },

    EditRealDummyBus({ }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `realRides/editRealDummyBus`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },
    editeRealStop({ }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `DummyRideStops/editeRealStop`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },
    editRealRideDateTime({ }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `realRides/editRealRideDateTime`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },
    editRealRide({ }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `realRides/editRealRide`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },
    getDummyRideStopsByRealRideId({ commit }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `DummyRideStops/getDummyRideStopsByRealRideId`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            commit("SetRideStops", res.data);
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },

    editePassenger({ }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `realRides/editePassenger`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },
    addRealRideDeduction({ }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `realRides/addRealRideDeduction`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },
    addCabtainRideBonus({ }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `realRides/addCabtainRideBonus`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },
    editePassengerCheckOut({ }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `realRides/editePassengerCheckOut`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },
    changeRealRide({ }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `realRides/changeRealRide`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },
    getRealRidesByBookingId({ commit }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `realRides/getRealRidesByBookingId`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            commit("SetRealRidesByBooking", res.data);
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },

    getRealRidesStatusLog({ commit }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `realRides/getRealRidesStatusLog`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            commit("SetRealRideStatusLog", res.data);
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },
    getRealRideStatusLog({ commit }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `realRides/getRealRideStatusLog`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            commit("SetRealRideStatusLog", res.data);
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },
    removeRealRideFromPlane({ commit }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `realRides/removeRealRideFromPlane`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },

    getRealRidesPlaneNumberforDropDown({ commit }) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `realRides/getRealRidesPlaneNumberforDropDown`,
            {},
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
                notificationToken: localStorage.getItem("notificationToken"),

              },
            }
          )
          .then((res) => {
            resolve(res);

            commit("SetRealRidesPlanDrop", res.data);
          })
          .catch((err) => console.log(err));
      });
    },

    hideOrShowRealRide({ commit }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `realRides/hideOrShowRealRide`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },  
    removeCaptainFromRealRide({ commit }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `realRides/removeCaptainFromRealRide`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    }, 
    convertRealRideToDummy({ commit }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `realRides/convertRealRideToDummy`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },
  },
};
