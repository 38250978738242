import api from "../api";
/* eslint-disable */
export default {
  namespaced: true,
  state: {
    realRideDetails: [],
    DetailsByDate: [],

  },
  getters: {
    getrealRideDetails(state) {
      return state.realRideDetails;
    }, getDetailsByDate(state) {
      return state.DetailsByDate;
    },
  },
  mutations: {
    SetrealRideDetails(state, data) {
      state.realRideDetails = [];
      state.realRideDetails = data.map(ride => ({
        ...ride,
        rideDate2: ride.rideDate,
        rideDate: new Date(ride.rideDate),
        totalExpenses: Number(ride.totalExpenses),
        totalRevenues: Number(ride.totalRevenues),

      }));
    }, SetsDetailsByDate(state, data) {
      state.DetailsByDate = [];
      state.DetailsByDate = data.map(ride => ({
        ...ride,
        rideDate: new Date(ride.rideDate),

      }));
    }
  },
  actions: {
    getrealRideDetails({ commit },data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `realRideDetails/getRealRidesDetails`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
            commit("SetrealRideDetails", res.data);
          })
          .catch((err) => console.log(err));
      });
    },
    getRealRidesDetailsByDate({ commit }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `realRideDetails/getRealRidesDetailsByDate`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
            commit("SetsDetailsByDate", res.data);
          })
          .catch((err) => console.log(err));
      });
    },
  },
};
