import api from "../api";
/* eslint-disable */
export default {
  namespaced: true,
  state: {
    UsersForNotification: [],
    UserLiveLocations: [],

  },
  getters: {
    getUsersForNotification(state) {
      return state.UsersForNotification;
    }, getUserLiveLocations(state) {
      return state.UserLiveLocations;
    },
  },
  mutations: {
    SetUsersForNotification(state, data) {
      state.UsersForNotification = [];
      state.UsersForNotification = data;
    }, SetUserLiveLocations(state, data) {
      
      state.UserLiveLocations = [];
      state.UserLiveLocations = data;
    },
  },
  actions: {
    addNotificationsUser({ }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `notification/addNotificationsUser`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {

            resolve(res);
          })
          .catch((err) => resolve(err));
      })
    },
    addNotificationsToSelectedUser({ }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `notification/addNotificationsToSelectedUser`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {

            resolve(res);
          })
          .catch((err) => resolve(err));
      })
    },

    
    addLiveLocation({ }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `notification/addLiveLocation`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {

            resolve(res);
          })
          .catch((err) => resolve(err));
      })
    },
    getLiveLocation({ }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `notification/getLiveLocation`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {

            resolve(res);
          })
          .catch((err) => resolve(err));
      })
    },
    getUserLiveLocations({commit }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `notification/getUserLiveLocations`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            commit("SetUserLiveLocations", res.data);

            resolve(res);
          })
          .catch((err) => resolve(err));
      })
    },
    getAllUsersForNotification({ commit }) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `notification/getAllUsersForNotification`,
            {},
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {

            resolve(res);
            commit("SetUsersForNotification", res.data);

          })
          .catch((err) => resolve(err));
      })
    },
  },
};
