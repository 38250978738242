import api from "../../api";
/* eslint-disable */
export default {
  namespaced: true,
  state: {
    BusCaptains: [],
  },
  getters: {
    getBusCaptains(state) {
      return state.BusCaptains;
    },
  },
  mutations: {
    SetBusCaptains(state, data) {
      state.BusCaptains = [];
      state.BusCaptains = data;
    },
  },
  actions: {

    getBusCaptainsByCaptainId({ commit }, id) {
      return new Promise((resolve, rej) => {
      api()
        .post(
          `BusCaptains/getBusCaptainsByCaptainId`,
          {
            id: id,
          },
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
          resolve(res);
          commit("SetBusCaptains", res.data);
        })
        .catch((err) => console.log(err));});
    },

    addBusCaptains({ dispatch }, { busId,captainId}) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `BusCaptains/addBusCaptain`,
            {busId:busId,
              captainId:captainId             
            },
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },


    deleteBusCaptain({ dispatch }, serial) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `BusCaptains/deleteBusCaptain`,
            { serial: serial },
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {

            resolve(res);

          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },


   
  },
};
