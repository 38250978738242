import api from "../../api";
/* eslint-disable */
export default {
  namespaced: true,
  state: {
    ContractorPercentageDeal: [],
  },
  getters: {
    getContractorPercentageDeal(state) {
      return state.ContractorPercentageDeal;
    },
  },
  mutations: {
    SetContractorPercentageDeal(state, data) {
      state.ContractorPercentageDeal = [];
      state.ContractorPercentageDeal = data;
    },
  },
  actions: {

    getContractorPercentageDealByContractorId({ commit }, id) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `ContractorPercentageDeal/getContractorPercentageDealByContractorId`,
            {
              id: id,
            },
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
            commit("SetContractorPercentageDeal", res.data);
          })
          .catch((err) => console.log(err));
      });
    },

    addContractorPercentageDeal({ dispatch }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `ContractorPercentageDeal/addContractorPercentageDeal`,
            {
              contractor_id: data.contractor_id,
              category_id:data.category_id,
              first_deduction:data.first_deduction,
              second_deduction: data.second_deduction,
              third_deduction:data.third_deduction,
              fourth_deduction: data.fourth_deduction,
              other_deduction: data.other_deduction,
              commission:data.commission,
              percentage:data.percentage,
              bonus:data.bonus,
            },
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },


    deleteContractorPercentageDeal({ dispatch }, serial) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `ContractorPercentageDeal/deleteContractorPercentageDeal`,
            { serial: serial },
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {

            resolve(res);

          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },



  },
};
