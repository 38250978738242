import api from "../api";
/* eslint-disable */
export default {
  namespaced: true,
  state: {
    Booking: [],

  },
  getters: {
    getBooking(state) {
      return state.Booking;
    },
  },
  mutations: {
    SetBooking(state, data) {
      state.Booking = [];
      state.Booking = data.map(ride => ({
        ...ride,
        rideDate: new Date(ride.rideDate),
        statusTime: new Date(ride.statusTime),
        bookDate: new Date(ride.bookDate),
        seatsCount: Number(ride.seatsCount),

        id: Number(ride.id)
    
      }));;;
    },

  },
  actions: {
     addPssenger({ commit },data) {
      return new Promise((resolve, rej) => {
      api()
        .post(
          `booking/book_ride`,
          data,
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
          
          resolve(res);
        })
        .catch((err) => resolve(err));})
    },
    book_ride({ commit },data) {
      return new Promise((resolve, rej) => {
      api()
        .post(
          `booking/book_ride2`,
          data,
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
          
          resolve(res);
        })
        .catch((err) => resolve(err));})
    },
    rebook({ commit },data) {
      return new Promise((resolve, rej) => {
      api()
        .post(
          `booking/rebook`,
          data,
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
          
          resolve(res);
        })
        .catch((err) =>{
          debugger
          resolve(err.response.data);
        } )})
    },


    getBookingByDate({ commit },data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `booking/getBookingByDate`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            commit("SetBooking", res.data);
            resolve()
          })
          .catch((err) => {

            console.log(err)
          });
      })
    },
    
  },
};
