import api from "../../api";
/* eslint-disable */
export default {
  namespaced: true,
  state: {
    Make: [],
  },
  getters: {
    getMake(state) {
      return state.Make;
    },
  },
  mutations: {
    SetMake(state, data) {
      state.Make = [];
      state.Make = data;
    },
  },
  actions: {
    fetchAllMakes({ commit }) {
      return new Promise((resolve, rej) => {
      api()
        .post(
          `Make/get_all_makes`,
          {},
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
          resolve(res);

          commit("SetMake", res.data);
        })
        .catch((err) => console.log(err));});
    },
    fetchMakesById({ commit }, id) {
      return new Promise((resolve, rej) => {
      api()
        .post(
          `Make/get_makes_by_id`,
          {
            id: id,
          },
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
                      resolve(res);

          commit("SetMake", res.data);
        })
        .catch((err) =>  resolve(err));});
    },

    addMake({ dispatch }, { name }) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `Make/add_make`,
            {
              name: name,
             
            },
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },
    editMake(
      { dispatch },
      { id, name  }
    ) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `Make/edite_make`,
            {
              id: id,
              name: name,
             
            },
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },

    deleteMake({ dispatch }, id) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `Make/delete_make`,
            { id: id },
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },
  },
};
