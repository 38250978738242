import api from "../../api";
/* eslint-disable */
export default {
  namespaced: true,
  state: {
    areas: [],
  },
  getters: {
    getAreas(state) {
      return state.areas;
    },
  },
  mutations: {
    SetAreas(state, data) {
      state.areas = [];
      state.areas = data;
    },
  },
  actions: {
    fetchAllAreas({ commit }) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `area/get_all_area`,
            {},
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            commit("SetAreas", res.data);
            resolve(res);
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
    fetchAreasByGov({ commit }, govId) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `area/get_areas_by_gov`,
            {
              govId: govId,
            },
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            commit("SetAreas", res.data);
            resolve(res);
          })
          .catch((err) => console.log(err));
      });
    },

    addArea({ dispatch }, { name, coordinates, gov_id }) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `area/add_area`,
            {
              name: name,
              coordinates: coordinates,
              gov_id: gov_id,
            },
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },
    editArea({ dispatch }, { id, name, coordinates, gov_id }) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `area/edite_area`,
            {
              id: id,
              name: name,
              coordinates: coordinates,
              gov_id: gov_id,
            },
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },

    deleteArea({ dispatch }, id) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `area/delete_area`,
            { id: id },
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },
  },
};
