import api from "../../api";
/* eslint-disable */
export default {
  namespaced: true,
  state: {
    Model: [],
  },
  getters: {
    getModel(state) {
      return state.Model;
    },
  },
  mutations: {
    SetModel(state, data) {
      state.Model = [];
      state.Model = data;
    },
  },
  actions: {
    fetchAllModel({ commit }) {
     
      return new Promise((resolve, rej) => { api()
        .post(
          `Model/get_all_models`,
          {},
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
          resolve(res);

          commit("SetModel", res.data);
        })
        .catch((err) => console.log(err));});
    },
    fetchModelByMake_id({ commit }, id) {
      return new Promise((resolve, rej) => {
      api()
        .post(
          `Model/get_models_by_make_id`,
          {
            id: id,
          },
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
          resolve(res);

          commit("SetModel", res.data);
        })
        .catch((err) => console.log(err));});
    },

    addModel({ dispatch }, { name,make_id}) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `Model/add_model`,
            {
              name:name,make_id:make_id
            },
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },
    editModel(
      {  },
      { id, name, make_id}
    ) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `Model/edite_model`,
            {
              id: id,
              name:name,make_id:make_id
             
            },
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },

    deleteModel({ dispatch }, id) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `Model/delete_model`,
            { id: id },
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },
  },
};
