import api from "../../api";
export default {
  namespaced: true,
  state: {
    chatTopics: [],
    chatData: [],
    chatWaitingToClose: [],
    chatDetailes: [],
    currentChatRoomId: 0,
    oldChats: [],
    oldChatDetailes: [],
    ClosedChatData: [],
    ClosedChatDetailes: [],
    redirectedChatTopics: [],
    redirectedChatData: [],
  },
  getters: {
    getChatTopics(state) {
      return state.chatTopics;
    },
    getChatData(state) {
      return state.chatData;
    },
    getChatWaitingToClose(state) {
      return state.chatWaitingToClose;
    },
    getChatSuppDetailes(state) {
      return state.chatDetailes;
    },
    getOldChats(state) {
      return state.oldChats;
    },
    getoldChatDetailes(state) {
      return state.oldChatDetailes;
    },
    getClosedChatData(state) {
      return state.ClosedChatData;
    },
    getClosedChatDetailes(state) {
      return state.ClosedChatDetailes;
    },
    getRedirectedChatTopics(state) {
      return state.redirectedChatTopics;
    },
    getRedirectedChatData(state) {
      return state.redirectedChatData;
    },
  },
  mutations: {
    SetChatTopics(state, data) {
      state.chatTopics = data.topics;
    },
    SetChatData(state, data) {
      state.chatData = [];
      state.chatData = data;
      // oder chatData by lastMessage.creationDate
      state.chatData.sort(
        (a, b) =>
          new Date(b.lastMessage.creationDate) -
          new Date(a.lastMessage.creationDate)
      );
    },
    SetChatsWaitingToClose(state, data) {
      state.chatWaitingToClose = [];
      state.chatWaitingToClose = data;
      // oder chatWaitingToClose by lastMessage.creationDate
      console.log(state.chatWaitingToClose);
      state.chatWaitingToClose.sort(
        (a, b) =>
          new Date(b.lastMessage.creationDate) -
          new Date(a.lastMessage.creationDate)
      );
    },
    SetChatDetailes(state, data) {
      state.chatDetailes = [];
      state.chatDetailes = data;
    },
    SetOldChats(state, data) {
      state.oldChats = [];
      state.oldChats = data;
      state.oldChats.sort(
        (a, b) =>
          new Date(b.creationDate) -
          new Date(a.creationDate)
      );
    },
    SetoldChatDetailes(state, data) {
      state.oldChatDetailes = [];
      state.oldChatDetailes = data.data;
    },
    SetClosedChatData(state, data) {
      state.ClosedChatData = [];
      state.ClosedChatData = data;
    },
    SetClosedChatDetailes(state, data) {
      state.ClosedChatDetailes = [];
      state.ClosedChatDetailes = data;
    },
    SetRedirectedChatTopics(state, data) {
      state.redirectedChatTopics = data.topics;
    },
    SetRedirectedChatData(state, data) {
      state.redirectedChatData = [];
      state.redirectedChatData = data;
    },
  },
  actions: {
    fetchAllChatTopics({ commit }) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `chat/get_topics`,
            {},
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            commit("SetChatTopics", res.data);
            resolve(res.data);
          })
          .catch((err) => console.log(err));
      });
    },
    fetchAllChatData({ commit }) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `chat/get_chats_by_topic`,
            {},
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            commit("SetChatData", res.data.data);
            resolve(res.data);
          })
          .catch((err) => console.log(err));
      });
    },
    fetchChatsWaitingToClose({ commit }) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `chat/get_chats_Waiting_to_close`,
            {},
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            commit("SetChatsWaitingToClose", res.data.data);
            resolve(res.data);
          })
          .catch((err) => console.log(err));
      });
    },
    fetchChatDetailes({ commit, state }, chatRoomId) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `chat/get_chat_topic_detailes`,
            { chatRoomId: chatRoomId },
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            state.currentChatRoomId = chatRoomId;
            commit("SetChatDetailes", res.data.data);
            resolve(res.data);
          })
          .catch((err) => console.log(err));
      });
    },
    send_message(
      { commit, state },
      { message, chatRoomId, ticketId, isRedirected }
    ) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `chat/topic_send_message`,
            {
              message: message,
              chatRoomId: chatRoomId,
              ticketId: ticketId,
              isRedirected: isRedirected,
            },
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            console.log(res);
            if (res.status == 200) {
              // state.chatDetailes[state.chatDetailes.length - 1].push(
              //   res.data.data
              // );
            }
            resolve(res.data.data);
          })
          .catch((err) => console.log(err));
      });
    },
    send_image({}, FormData) {
      return new Promise((resolve, rej) => {
        api()
          .post(`chat/topic_send_image`, FormData, {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          })
          .then((res) => {
            console.log(res);
            if (res.status == 200) {
              // state.chatDetailes[state.chatDetailes.length - 1].push(
              //   res.data.data
              // );
            }
            resolve(res.data.data);
          })
          .catch((err) => console.log(err));
      });
    },
    suppMarkAsSeen({ commit }, chatRoomId) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `chat/topicMarkAsSeen`,
            { chatRoomId: chatRoomId },
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => console.log(err));
      });
    },
    requestCloseTopic({ commit }, ticketId) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `chat/ticketRequestClose`,
            { ticketId: ticketId },
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => console.log(err));
      });
    },
    getOldChats({ commit }, chatRoomId) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `chat/get_old_chats_by_topic`,
            { chatRoomId },
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            console.log(chatRoomId);
            commit("SetOldChats", res.data.data);
            resolve(res.data);
          })
          .catch((err) => console.log(err));
      });
    },
    getoldChatDetailes({ commit }, ticketId) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `chat/get_chat_topic_detailes_by_id`,
            { ticketId },
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            if (res.status == 200) {
              commit("SetoldChatDetailes", res.data);
            }

            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },
    fetchAllClosedChatData({ commit }) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `chat/get_closed_chats_by_topic`,
            {},
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            commit("SetClosedChatData", res.data.data);
            resolve(res.data);
          })
          .catch((err) => console.log(err));
      });
    },
    fetchClosedChatDetailes({ commit, state }, chatRoomId) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `chat/get_closed_chat_topic_detailes`,
            { chatRoomId: chatRoomId },
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            state.currentChatRoomId = chatRoomId;
            commit("SetClosedChatDetailes", res.data.data);
            resolve(res.data);
          })
          .catch((err) => console.log(err));
      });
    },
    sendChatToLiveOps(
      { commit, state },
      { chatRoomId, isItcaptainTicket, ticketId }
    ) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `chat/ticketToLiveOps`,
            {
              isItcaptainTicket: isItcaptainTicket,
              ticketId: ticketId,
            },
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            console.log(res);
            if (res.status == 200) {
              /// remove chat from chatData by chatRoomId
              let index = state.chatData.findIndex(
                (x) => x.chatRoomId == chatRoomId
              );
              state.chatData.splice(index, 1);
            }
            resolve(res.data.data);
          })
          .catch((err) => console.log(err));
      });
    },
    sendChatToCustomer(
      { commit, state },
      { chatRoomId, isItCustomerTicket, ticketId }
    ) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `chat/ticketToCustomerService`,
            {
              isItCustomerTicket: isItCustomerTicket,
              ticketId: ticketId,
            },
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            console.log(res);
            if (res.status == 200) {
              /// remove chat from chatData by chatRoomId
              let index = state.chatData.findIndex(
                (x) => x.chatRoomId == chatRoomId
              );
              state.chatData.splice(index, 1);
            }
            resolve(res.data.data);
          })
          .catch((err) => console.log(err));
      });
    },
    fetchAllRedirectedChatTopics({ commit }) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `chat/get_redircted_topics`,
            {},
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            commit("SetRedirectedChatTopics", res.data);
            resolve(res.data);
          })
          .catch((err) => console.log(err));
      });
    },
    fetchAllRedirectedChatData({ commit }) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `chat/get_redirected_chats_by_topic`,
            {},
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            commit("SetRedirectedChatData", res.data.data);
            resolve(res.data);
          })
          .catch((err) => console.log(err));
      });
    },
  },
};
