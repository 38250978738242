import api from "../../api";
export default {
  namespaced: true,
  state: {
    chatSuppData: [],
    chatSuppDetailes: [],
    currentChatRoomId: 0,
  },
  getters: {
    getChatSuppData(state) {
      return state.chatSuppData;
    },
    getChatSuppDetailes(state) {
      return state.chatSuppDetailes;
    },
  },
  mutations: {
    SetChatSuppData(state, data) {
      state.chatSuppData = [];
      state.chatSuppData = data;
    },
    SetChatSuppDetailes(state, data) {
      state.chatSuppDetailes = [];
      state.chatSuppDetailes = data;
    },
  },
  actions: {
    fetchAllChatSuppData({ commit }) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `chat/get_support_chats`,
            {},
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            commit("SetChatSuppData", res.data.data);
            resolve(res.data);
          })
          .catch((err) => console.log(err));
      });
    },
    fetchChatSuppDetailes({ commit, state }, chatRoomId) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `chat/get_support_chat_detailes`,
            { chatRoomId: chatRoomId },
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            state.currentChatRoomId = chatRoomId;
            commit("SetChatSuppDetailes", res.data.data);
            resolve(res.data);
          })
          .catch((err) => console.log(err));
      });
    },
    send_supp_message({ commit, state }, { message, chatRoomId }) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `chat/supp_send_message`,
            { message: message, chatRoomId: chatRoomId },
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            console.log(res);
            if (res.status == 200) {
              state.chatSuppDetailes[state.chatSuppDetailes.length - 1].push(
                res.data.data
              );
            }
            resolve(res.data);
          })
          .catch((err) => console.log(err));
      });
    },
    suppMarkAsSeen({ commit }, chatRoomId) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `chat/SuppMarkAsSeen`,
            { chatRoomId: chatRoomId },
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => console.log(err));
      });
    },
  },
};
