import api from "../api";
export default {
  namespaced: true,
  state: {
    usersBookingDetails: [],
    UserTotalBooking:[],
    UserCompeleteBookings:[],
    UserCanceledBookings:[],
    UserMissedBookings:[],
    UserTotalSearch:[],
  },
  getters: {
    getUsersBookingDetails(state) {
      return state.usersBookingDetails;
    },
    getUserTotalBooking(state) {
      return state.UserTotalBooking;
    },
    getUserCompeletedBookings(state) {
      return state.UserCompeleteBookings;
    },
    getUserCanceledBookings(state) {
      return state.UserCanceledBookings;
    },
    getUserMissedBookings(state) {
      return state.UserMissedBookings;
    },
    getUserTotalsearch(state) {
      return state.UserTotalSearch;
    },
  },
  mutations: {
    SetUsersBookingDetails(state, data) {
      state.usersBookingDetails = [];
      state.usersBookingDetails = data;
     
    },
    SetUserTotalBooking(state, data) {
      state.UserTotalBooking = [];
      state.UserTotalBooking = data;
    },
    SetUserCompeletedBookings(state, data) {
      state.UserCompeleteBookings = [];
      state.UserCompeleteBookings = data;
    },
    SetUserCanceledBookings(state, data) {
      state.UserCanceledBookings = [];
      state.UserCanceledBookings = data;
    },
    SetUserMissedBookings(state, data) {
      state.UserMissedBookings = [];
      state.UserMissedBookings = data;
    },
    SetUserTotalSearch(state, data) {
      state.UserTotalSearch = [];
      state.UserTotalSearch = data;
    },
  },
  actions: {
    GetUsersBookingDetails({ commit }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `usersDetails/getUsersBookingDetails`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
            commit("SetUsersBookingDetails", res.data);
          })
          .catch((err) => console.log(err));
      })
    },
    GetUserTotalBookings({ commit }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `usersDetails/getUserTotalBookings`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
            commit("SetUserTotalBooking", res.data);
          })
          .catch((err) => console.log(err));
      })
    },
    GetUserCompeletedBookings({ commit }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `usersDetails/getUserCompeletedBookings`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
            commit("SetUserCompeletedBookings", res.data);
          })
          .catch((err) => console.log(err));
      })
    },
    GetUserCanceledBookings({ commit }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `usersDetails/getUserCanceledBookings`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
            commit("SetUserCanceledBookings", res.data);
          })
          .catch((err) => console.log(err));
      })
    },
    GetUserMissedBookings({ commit }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `usersDetails/getUserMissedBookings`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
            commit("SetUserMissedBookings", res.data);
          })
          .catch((err) => console.log(err));
      })
    },
    GetUserTotalSearch({ commit }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `usersDetails/getTotalSearch`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
            commit("SetUserTotalSearch", res.data);
          })
          .catch((err) => console.log(err));
      })
    },
  }
}