import api from "../../api";
export default {
    namespaced: true,
    state: {
        notifyRequests: [],
    },
    getters: {
      getNotifyRequests(state){
        return state.notifyRequests;
      },
    },
    mutations: {
      SetNotifyRequests(state,data){
        state.notifyRequests=[];
        state.notifyRequests=data;
      },
    },
    actions: {
        getNotifyRequestsByRangeDates({ commit }, data) {
            return new Promise((resolve, rej) => {
                api()
                  .post(`NotifyRequest/getNotifyRequest`, {
                    "fromDate": data.fromDate,
                    "toDate": data.toDate
                }, {
                    headers: {
                      Authorization: localStorage.getItem("JwtToken"),
                    },
                  })
                  .then((res) => {
                    resolve(res);
                    commit("SetNotifyRequests",res.data.data)
                  })
                  .catch((err) => {
                    resolve(err.response);
                });
            });
        }
    }
};