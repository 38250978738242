import api from "../../api";
/* eslint-disable */
export default {
  namespaced: true,
  state: {
    Compensation: [],
  },
  getters: {
    getCompensation(state) {
      
      return state.Compensation;
    },
  },
  mutations: {
    SetCompensation(state, data) {
      
      state.Compensation = [];
      state.Compensation = data;
    },
  },
  actions: {
    fetchAllCompensation({ commit }) {
      return new Promise((resolve, rej) => {
      api()
        .post(
          `compensations/getCompensation`,
          {},
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
          resolve(res);
          commit("SetCompensation", res.data);
        })
        .catch((err) => console.log(err));})
    },


    addCompensation({ dispatch }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `compensations/addCompensation`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },
    editeCompensation(
      { dispatch },
      data
    ) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `compensations/editeCompensation`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {

            resolve(res);

          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },

    deleteCompensation({ dispatch }, serial) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `compensations/deleteCompensation`,
            { serial: serial },
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {

            resolve(res);

          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },
    getCompensationByPersonTypeId({ commit }, personTypeId) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `compensations/getCompensationByPersonTypeId`,
            { personTypeId: personTypeId },
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            
            commit("SetCompensation", res.data);

            resolve(res);

          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },
  },
};
