import api from "../../api";
/* eslint-disable */
export default {
  namespaced: true,
  state: {
    governates: [],
  },
  getters: {
    getGovernates(state) {
      return state.governates;
    },
  },
  mutations: {
    SetGovernates(state, data) {
      state.governates = [];
      state.governates = data;
    },
  },
  actions: {
    fetchAllGovernates({ commit }) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `governorate/get_all_governorates`,
            {},
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            commit("SetGovernates", res.data);
            resolve(res);
          })
          .catch((err) => console.log(err));
      });
    },
    fetchGovernatesByCountry({ commit }, counrtyId) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `governorate/get_governorates_by_Country`,
            {
              counrtyId: counrtyId,
            },
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            commit("SetGovernates", res.data);
            resolve(res); 
          })
          .catch((err) => console.log(err));
      });
    },

    addstopData({ dispatch }, { name_ar, name_en, coordinates, countryID }) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `governorate/add_gov`,
            {
              name_ar: name_ar,
              name_en: name_en,
              coordinates: coordinates,
              countryID: countryID,
            },
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },
    editgov({ dispatch }, { id, name_ar, name_en, coordinates, countryID }) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `governorate/edite_gov`,
            {
              id: id,
              name_ar: name_ar,
              name_en: name_en,
              coordinates: coordinates,
              countryID: countryID,
            },
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },

    deleteGov({ dispatch }, id) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `governorate/delete_gov`,
            { id: id },
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },
  },
};
