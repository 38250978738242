import api from "../../api";
/* eslint-disable */
export default {
  namespaced: true,
  state: {
    Captains: [], PersonDoc:[],CaptainsDrop:[]
  },
  getters: {
    getCaptains(state) {
      return state.Captains;
    },getPersonDoc(state) {
      return state.PersonDoc;
    },getCaptainsDrop(state) {
      return state.CaptainsDrop;
    },
  },
  mutations: {
    SetCaptains(state, data) {
      state.Captains = [];
      state.Captains = data;
    },SetPersonDoc(state, data) {
      state.PersonDoc = [];
      state.PersonDoc = data;
    },SetCaptainsDrop(state, data) {
      state.CaptainsDrop = [];
      state.CaptainsDrop = data;
    },
  },
  actions: {
    fetchAllCaptains({ commit }) {
      return new Promise((resolve, rej) => {
      api()
        .post(
          `captains/get_all_captains`,
          {},
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
                      resolve(res);

          commit("SetCaptains", res.data);
        })
        .catch((err) => console.log(err));});
    },
    getCaptainsDrop({ commit }) {
      return new Promise((resolve, rej) => {
      api()
        .post(
          `captains/getCaptainsDrop`,
          {},
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
                      resolve(res);

          commit("SetCaptainsDrop", res.data);
        })
        .catch((err) => console.log(err));});
    },
    getCaptainById({ commit },id) {
      return new Promise((resolve, rej) => {
      api()
        .post(
          `captains/getCaptainById`,
          {id:id},
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
                      resolve(res);

          commit("SetCaptains", res.data);
        })
        .catch((err) => console.log(err));});
    },

    
    fetchpersonDocBypersonID({ commit }, id) {
      return new Promise((resolve, rej) => {
      api()
        .post(
          `captains/get_personDoc_by_personID`,
          {
            id: id,
          },
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
          resolve(res);
          commit("SetPersonDoc", res.data);
        })
        .catch((err) => console.log(err));});
    },



    addCaptain({ }, formData) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `captains/add_captain`,
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',

                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },
    editCaptain(
      { },
      formData
    ) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `captains/edite_captain`,
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',

                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {

            resolve(res);

          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },

    deleteCaptain({ }, id) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `captains/delete_captain`,
            { id: id },
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {

            resolve(res);

          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },

    UploadCaptionFile({ }, Formdata) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `Captains/UploadCaptionFile`,
            Formdata,
            {
              headers: {
                'Content-Type': 'multipart/form-data',

                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },






    
    removeCaptionFile({ }, {captainsId,docNameId}) {
  return new Promise((resolve, rej) => {
    api()
      .post(
        `Captains/removeCaptionFile`,
        { docNameId: docNameId,captainsId:captainsId },
        {
          headers: {
            Authorization: localStorage.getItem("JwtToken"),
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        resolve(err.response);
      });
  });
},




    
  },
};
