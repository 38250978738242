import api from "../../api";
/* eslint-disable */
export default {
  namespaced: true,
  state: {
    RideCategoryFare: [],
  },
  getters: {
    getRideCategoriesFare(state) {
      return state.RideCategoryFare;
    },
  },
  mutations: {
    SetRideCategoriesFare(state, data) {
      state.RideCategoryFare = [];
      state.RideCategoryFare = data;
    },
  },
  actions: {

    fetchRideCategoriesFareById({ commit }, id) {
      return new Promise((resolve, rej) => {
      api()
        .post(
          `RideCategoryFare/get_ride_categories_fares_by_category_id`,
          {
            id: id,
          },
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
          resolve(res);
          commit("SetRideCategoriesFare", res.data);
        })
        .catch((err) => console.log(err));});
    },

    addRideCategoryFare({ dispatch }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `RideCategoryFare/add_ride_category_fares`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },

   
  },
};
