import api from "../../api";
export default {
  namespaced: true,
  state: {
    schedules: [],
  },
  getters: {
    getSchedules(state) {
      return state.schedules;
    },
  },
  mutations: {
    SetSchedules(state, data) {
      state.schedules = [];
      state.schedules = data;
    },
  },
  actions: {
    fetchAllSchedules({ commit }, id) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `schedules/get_all_schedules`,
            { },
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            commit("SetSchedules", res.data);
            resolve(res);
          })
          .catch((err) => console.log(err));
      });
    },
    fetchAllSchedulesForRoute({ commit }, id) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `schedules/get_all_schedules_for_route`,
            { id: id },
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            commit("SetSchedules", res.data);
            resolve(res.data);
          })
          .catch((err) => console.log(err));
      });
    },

    addSchedule(
      { dispatch },
      data
    ) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `schedules/add_schedule`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },
    editeSchedule(
      { dispatch },
data
    ) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `schedules/edite_schedule`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },
    deleteSchedule({ dispatch },  id) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `schedules/delete_schedule`,
            {
              id: id,
            },
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },
    runSchedule({ dispatch },{ id,now,date}){
      return new Promise((resolve, rej) => {
        api()
          .post(
            `schedules/run_schedule`,
            {
              schedueId: id,
              runNow:now,
              runAtExactDate:date
            },
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },
    activeSchedule({ dispatch }, id){
      return new Promise((resolve, rej) => {
        api()
          .post(
            `schedules/activate_schedule`,
            {
              scheduleList: id,
            },
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },
    deactiveSchedule({ dispatch }, id){
      return new Promise((resolve, rej) => {
        api()
          .post(
            `schedules/deactivate_schedule`,
            {
              scheduleList: id,
            },
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },
    deleteScheduledRunSchedule({ dispatch }, id){
      return new Promise((resolve, rej) => {
        api()
          .post(
            `schedules/delete_scheduled_runSchedule`,
            {
              scheduleList: id,
            },
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    }
  },
};
