import api from "../../api";
/* eslint-disable */
export default {
  namespaced: true,
  state: {
    User: [],
    numberOfUsers: [],
    Trips: [],
    UserbyGropingDate: [],
    UserSearch: [],
    UserNotifications: [],
    UserByNameorPhone: [],
    UserTransferLog: [],

  },
  getters: {
    getUser(state) {
      return state.User;
    },getNumberOfUsers(state){
      return state.numberOfUsers;
    },getTrips(state) {
      return state.Trips;
    }, getUserbyGropingDate(state) {
      return state.UserbyGropingDate;
    }, getUserSearch(state) {
      return state.UserSearch;
    },getUserNotifications(state) {
      return state.UserNotifications;
    },getUserByNameorPhone(state) {
      return state.UserByNameorPhone;
    },getUserTransferLog(state) {
      return state.UserTransferLog;
    },
    getUserBooking(state) {
      return state.UserBooking;
    },getUserByDate(state) {
      return state.UserByDate;
    },getUserSearchByDate(state) {
      return state.UserSearchByDate;
    },getUserCancel(state) {
      return state.UserCancel;
    },getTotalBooking(state){
      return state.TotalBooking;
    },getBookingDetails(state){
      return state.BookingDetails;
    }
  },
  mutations: {
    SetUser(state, data) {
      state.User = [];
      state.User = data;
    },SetNumberOfUsers(state, data) {
      state.numberOfUsers = [];
      state.numberOfUsers = data;
    }, SetTrips(state, data) {
      state.Trips = [];
      state.Trips = data.sort((a, b) => Number(b.id) - Number(a.id));
    }, SetUserbyGropingDate(state, data) {
      state.UserbyGropingDate = [];
      state.UserbyGropingDate = data;
    }, SetUserSearch(state, data) {
      state.UserSearch = [];
      state.UserSearch =   data.sort((a, b) => Number(b.id) - Number(a.id));
;
    },SetUserNotifications(state, data) {
      state.UserNotifications = [];
      state.UserNotifications = data.sort((a, b) => Number(b.serial) - Number(a.serial));

    },SetUserByNameorPhone(state, data) {
      state.UserByNameorPhone = [];
      state.UserByNameorPhone = data.length > 0 ? data.slice(0, 1) : [];
    },SetUserTransferLog(state, data) {
      state.UserTransferLog = [];
      state.UserTransferLog = data;
    },
    SetUserBooking(state, data) {
      state.UserBooking = [];
      state.UserBooking = data;
    }, SetUserByDate(state, data) {
      state.UserByDate = [];
      state.UserByDate = data;
    },SetUserSearchByDate(state, data) {
      state.UserSearchByDate = [];
      state.UserSearchByDate = data;
    },SetUserCancel(state, data) {
      state.UserCancel = [];
      state.UserCancel = data;
    },SetBooking(state,data){
      state.TotalBooking=[];
      state.TotalBooking=data;
     
    },SetBookingDetails(state,data){
      state.BookingDetails=[];
      state.BookingDetails=data;
    }
  },
  actions: {
    getNumberOfUsers({ commit }) {

      return new Promise((resolve, rej) => {
        api()
          .post(
            `users/getNumberOfUsers`,
            {},
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
            commit("SetNumberOfUsers", res.data);
          })
          .catch((err) => console.log(err));
      })
    },
    getAllUser({ commit },data) {

      return new Promise((resolve, rej) => {
        api()
          .post(
            `users/getAllUser`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
            commit("SetUser", res.data);
          })
          .catch((err) => console.log(err));
      })
    },
    getAllUserbyGropingDate({ commit }) {
      api().post(`users/getAllUserbyGropingDate`, {}, {
        headers: {
          'Authorization': localStorage.getItem("JwtToken")
        }
      })
        .then(res => {
          commit("SetUserbyGropingDate", res.data);
        })
        .catch(err => {
        })

    },
    getUserTripsById({ commit }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `users/getUserTripsById`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
            commit("SetTrips", res.data);
          })
          .catch((err) => console.log(err));
      })
    },

    getUserByNameorPhone({ commit }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `users/getUserByNameorPhone`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
            commit("SetUserByNameorPhone", res.data);
          })
          .catch((err) => resolve(err.response.data));
      })
    },


    
    getUserSearchById({ commit }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `users/getUserSearchById`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
            commit("SetUserSearch", res.data);
          })
          .catch((err) => console.log(err));
      })
    },
    getUserNotificationsById({ commit }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `users/getUserNotificationsById`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
            commit("SetUserNotifications", res.data);
          })
          .catch((err) => console.log(err));
      })
    },
    getUserTransferLogs({ commit }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `userTransferLog/getUserTransferLogs`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
            commit("SetUserTransferLog", res.data);
          })
          .catch((err) => console.log(err));
      })
    },
    getUserBookingByDate({ commit }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `users/getUserBookingByDate`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
            commit("SetUserBooking", res.data);
          })
          .catch((err) => console.log(err));
      })
    },
    getUserByDate({ commit }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `users/getUserByDate`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
            commit("SetUserByDate", res.data);
          })
          .catch((err) => console.log(err));
      })
    },
    getUserSearchByDate({ commit }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `users/getUserSearchByDate`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
            commit("SetUserSearchByDate", res.data);
          })
          .catch((err) => console.log(err));
      })
    },
    getUserCancelByDate({ commit }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `users/getUserCancelByDate`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
            commit("SetUserCancel", res.data);
          })
          .catch((err) => console.log(err));
      })
    },
    getTotalBooking({commit}){
      return new Promise((resolve,rej)=>{
        api()
          .post(`TotalBookingCounts/GetTotalBookingPerDay`,
            {},
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          ).then((res)=>{
            resolve(res);
            commit("SetBooking",res.data);
          })
          .catch((err) => console.log(err));
      })
    },
    getBookingDetails({ commit }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(`TotalBookingCounts/GetBookingDetailsPerDay`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          ).then((res) => {
            resolve(res);
            commit("SetBookingDetails",res.data)
          })
          .catch((err)=>console.log(err))
      })
    }
    
  },
};
