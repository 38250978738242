import api from "../api";
/* eslint-disable */
export default {
  namespaced: true,
  state: {
    bookingDetails: [],
  },
  getters: {
    getbookingDetails(state) {
      return state.bookingDetails;
    },
  },
  mutations: {
    SetbookingDetails(state, data) {
      state.bookingDetails = [];
      state.bookingDetails = data.map(ride => ({
        ...ride,
        rideDate:new Date(ride.rideDate),

        bookingId: Number(ride.bookingId),
        customerId: Number(ride.customerId),
        routeId: Number(ride.routeId),
        statusId: Number(ride.statusId),

    
      }));;;
    },
  },
  actions: {
    getBookingByDate({ commit },data) {
      return new Promise((resolve, rej) => {
      api()
        .post(
          `bookingDetails/bookingDetailsByDate`,
          data,
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
          resolve(res);
          commit("SetbookingDetails", res.data);
        })
        .catch((err) => console.log(err));})
    },

  },
};
